import React from "react";
import { ResponsiveImage } from "@quintype/components";

import AuthorDefaultLogo from "../../atoms/author-default-img";
import SocialShareIcons from "../../molecules/social-share";
import "./author-card.m.css";

const AuthorCard = ({ author }) => {
  const name = author.name;
  const avatarURL = author["avatar-s3-key"];
  const authorAvatarUrl = author["avatar-url"];
  return (
    <div styleName="wrapper">
      <div styleName="author-image">
        {avatarURL ? (
          <ResponsiveImage slug={avatarURL} aspectRatio={[1, 1]} width={200} height={200} alt={name.substring(1)} />
        ) : authorAvatarUrl ? (
          <img src={authorAvatarUrl} alt={name.substring(1)} width={200} height={200} />
        ) : (
          <AuthorDefaultLogo width={200} height={200} />
        )}
      </div>
      <div styleName="content">
        <h2 styleName="author-name">{author.name}</h2>
        {author.bio && <div styleName="author-bio">{author.bio}</div>}
        <div styleName="share-icons">
          <span styleName="connect-text">Connect: </span>
          <div styleName="social-share-icons">
            <SocialShareIcons socials={author.social} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;

import React from "react";
import "./newsletter-card.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

const NewsletterCard = ({ item, onSignup, isSelected }) => {
  const { title, summary, id, tag_name, duration } = item;

  return (
    <div styleName={`wrapper ${isSelected.includes(id) ? "selected-card" : ""}`}>
      <div>
        <h2>{title}</h2>
        <p>{summary}</p>
      </div>
      <div>
        <div styleName="time-preview">
          <div styleName="time">
            <SvgIconHandler type="clock" height="16px" width="16px" styleName="icon" /> {duration}
          </div>
        </div>
        <button onClick={() => onSignup(id, tag_name, title)}>
          Sign up <SvgIconHandler type="addrounded" height="18px" width="18px" styleName="icon" />
        </button>
      </div>
    </div>
  );
};

export default NewsletterCard;

import React, { useState, useEffect } from "react";
import { string, shape, object } from "prop-types";
import Separator from "../../atoms/separator";
import NewsShotsCard from "../../molecules/news-shots-card";
import TitleWithBorder from "../../molecules/title-with-border";
import { AdContainer } from "../../ads";

import "./news-shots-page.m.css";

const NewsShotsPage = ({ data }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  if (!data?.collection?.items?.length) return null;
  const { collection } = data;

  const stories = collection?.items?.filter((item) => item?.type === "story").slice(0, 16);

  return (
    <div styleName="wrapper" className="container">
      {isMobile ? (
        <>
          <TitleWithBorder name="News Shots" />
          <div styleName="stories">
            {stories.slice(0, 3).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          {isMobile && stories.length > 1 ? (
            <div styleName="ad-wrapper" className="hide-desktop">
              <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156938221-0" mobile="DH_MWeb_HP_Top" />
            </div>
          ) : null}
          <div styleName="stories">
            {stories.slice(3, 7).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          {isMobile && stories.length > 1 ? (
            <div styleName="ad-wrapper" className="hide-desktop">
              <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156970809-0" mobile="DH_MWeb_HP_Mid" />
            </div>
          ) : null}
          <div styleName="stories">
            {stories.slice(7, 10).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          {isMobile ? (
            <div styleName="ad-wrapper" className="hide-desktop">
              <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156998073-0" mobile="DH_MWeb_HP_Bottom" />
            </div>
          ) : null}
        </>
      ) : (
        <>
          {!isMobile ? (
            <div className="hide-mobile ad">
              <Separator />
              <div styleName="top-ad">
                <AdContainer id="div-gpt-ad-1680156194475-0" desktop="DH_Desktop_HP_Billboard_Top" />
              </div>
            </div>
          ) : null}
          <TitleWithBorder name="News Shots" />
          <div styleName="stories">
            {stories.slice(0, 3).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          <div styleName="stories">
            {stories.slice(3, 6).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          {!isMobile ? (
            <div className="hide-mobile ad">
              <Separator />
              <AdContainer id="div-gpt-ad-1680156254107-0" desktop="DH_Desktop_HP_Billboard_Mid" />
              <Separator />
            </div>
          ) : null}
          <div styleName="stories">
            {stories.slice(6, 9).map((item, index) => {
              return <NewsShotsCard key={index} story={item.story} />;
            })}
          </div>
          {!isMobile ? (
            <div className="hide-mobile ad">
              <Separator />
              <AdContainer id="div-gpt-ad-1680155832150-0" desktop="DH_Desktop_HP_Billboard_Bottom" />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

NewsShotsPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { NewsShotsPage };

export const electionData = {
  MIZORAM: [
    {
      id: 1,
      constituencyName: "Hachhek",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-hachhek-assembly-constituency-result-2769883"
    },
    {
      id: 2,
      constituencyName: "Dampa",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-dampa-assembly-constituency-result-2769895"
    },
    {
      id: 3,
      constituencyName: "Mamit",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-mamit-assembly-constituency-result-2769896"
    },
    {
      id: 4,
      constituencyName: "Tuirial",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tuirial-assembly-constituency-result-2769897"
    },
    {
      id: 5,
      constituencyName: "Kolasib",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-kolasib-assembly-constituency-result-2769898"
    },
    {
      id: 6,
      constituencyName: "Serlui",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-serlui-assembly-constituency-result-2769899"
    },
    {
      id: 7,
      constituencyName: "Tuivawl",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tuivawl-assembly-constituency-result-2769900"
    },
    {
      id: 8,
      constituencyName: "Chalfilh",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-chalfilh-assembly-constituency-result-2769901"
    },
    {
      id: 9,
      constituencyName: "Tawi",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tawi-assembly-constituency-result-2769902"
    },

    {
      id: 22,
      constituencyName: "Tuichang",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tuichang-assembly-constituency-result-2769915"
    },
    {
      id: 40,
      constituencyName: "Palak",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-palak-assembly-constituency-result-2769933"
    },
    {
      id: 21,
      constituencyName: "Lengteng",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lengteng-assembly-constituency-result-2769914"
    },
    {
      id: 39,
      constituencyName: "Siaha",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-siaha-assembly-constituency-result-2769932"
    },
    {
      id: 10,
      constituencyName: "Aizawl North I",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-north-i-assembly-constituency-result-2769903"
    },
    {
      id: 11,
      constituencyName: "Aizawl North II",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-north-ii-assembly-constituency-result-2769904"
    },
    {
      id: 12,
      constituencyName: "Aizawl North III",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-north-iii-assembly-constituency-result-2769905"
    },
    {
      id: 13,
      constituencyName: "Aizawl East I",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-east-i-assembly-constituency-result-2769906"
    },
    {
      id: 14,
      constituencyName: "Aizawl East II",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-east-ii-assembly-constituency-result-2769907"
    },
    {
      id: 15,
      constituencyName: "Aizawl West I",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-west-i-assembly-constituency-result-2769908"
    },
    {
      id: 16,
      constituencyName: "Aizawl West II",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-west-ii-assembly-constituency-result-2769909"
    },
    {
      id: 17,
      constituencyName: "Aizawl West III",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-west-iii-assembly-constituency-result-2769910"
    },
    {
      id: 18,
      constituencyName: "Aizawl South I",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-south-i-assembly-constituency-result-2769911"
    },
    {
      id: 19,
      constituencyName: "Aizawl South II",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-south-ii-assembly-constituency-result-2769912"
    },
    {
      id: 20,
      constituencyName: "Aizawl South III",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-aizawl-south-iii-assembly-constituency-result-2769913"
    },
    {
      id: 23,
      constituencyName: "Champhai North",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-champhai-north-assembly-constituency-result-2769916"
    },
    {
      id: 24,
      constituencyName: "Champhai South",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-champhai-south-assembly-constituency-result-2769917"
    },
    {
      id: 25,
      constituencyName: "East Tuipui",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-east-tuipui-assembly-constituency-result-2769918"
    },
    {
      id: 26,
      constituencyName: "Serchhip",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-serchhip-assembly-constituency-result-2769919"
    },
    {
      id: 27,
      constituencyName: "Tuikum",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tuikum-assembly-constituency-result-2769920"
    },
    {
      id: 28,
      constituencyName: "Hrangturzo",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-hrangturzo-assembly-constituency-result-2769921"
    },
    {
      id: 29,
      constituencyName: "South Tuipui",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-south-tuipui-assembly-constituency-result-2769922"
    },
    {
      id: 30,
      constituencyName: "Lunglei North",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lunglei-north-assembly-constituency-result-2769923"
    },
    {
      id: 31,
      constituencyName: "Lunglei East",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lunglei-east-assembly-constituency-result-2769924"
    },
    {
      id: 32,
      constituencyName: "Lunglei West",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lunglei-west-assembly-constituency-result-2769925"
    },
    {
      id: 33,
      constituencyName: "Lunglei South",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lunglei-south-assembly-constituency-result-2769926"
    },
    {
      id: 34,
      constituencyName: "Thorang",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-thorang-assembly-constituency-result-2769927"
    },
    {
      id: 35,
      constituencyName: "West Tuipui",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-west-tuipui-assembly-constituency-result-2769928"
    },
    {
      id: 36,
      constituencyName: "Tuichawng",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-tuichawng-assembly-constituency-result-2769929"
    },
    {
      id: 37,
      constituencyName: "Lawngtlai West",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lawngtlai-west-assembly-constituency-result-2769930"
    },
    {
      id: 38,
      constituencyName: "Lawngtlai East",
      primarySection: "Mizoram (Elections)",
      secondarySection: "Mizoram (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/mizoram/mizoram-assembly-elections-2023-lawngtlai-east-assembly-constituency-result-2769931"
    }
  ],
  CHHATTISGARH: [
    {
      id: 1,
      constituencyName: "Bharatpur-Sonhat",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bharatpur-sonhat-assembly-constituency-result-2769937"
    },
    {
      id: 2,
      constituencyName: "Manendragarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-manendragarh-assembly-constituency-result-2769938"
    },
    {
      id: 3,
      constituencyName: "Baikunthpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-baikunthpur-assembly-constituency-result-2769939"
    },
    {
      id: 4,
      constituencyName: "Premnagar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-premnagar-assembly-constituency-result-2769940"
    },
    {
      id: 5,
      constituencyName: "Bhatgaon",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bhatgaon-assembly-constituency-result-2769941"
    },
    {
      id: 6,
      constituencyName: "Pratappur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-pratappur-assembly-constituency-result-2769942"
    },
    {
      id: 7,
      constituencyName: "Ramanujganj",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-ramanujganj-assembly-constituency-result-2769944"
    },
    {
      id: 8,
      constituencyName: "Samri",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-samri-assembly-constituency-result-2769946"
    },
    {
      id: 9,
      constituencyName: "Lundra",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-lundra-assembly-constituency-result-2769949"
    },
    {
      id: 10,
      constituencyName: "Ambikapur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-ambikapur-assembly-constituency-result-2769950"
    },
    {
      id: 11,
      constituencyName: "Sitapur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-sitapur-assembly-constituency-result-2769951"
    },
    {
      id: 12,
      constituencyName: "Jashpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-jashpur-assembly-constituency-result-2769952"
    },
    {
      id: 13,
      constituencyName: "Kunkuri",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kunkuri-assembly-constituency-result-2769953"
    },
    {
      id: 14,
      constituencyName: "Pathalgaon",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-pathalgaon-assembly-constituency-result-2769954"
    },
    {
      id: 15,
      constituencyName: "Lailunga",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-lailunga-assembly-constituency-result-2769955"
    },
    {
      id: 16,
      constituencyName: "Raigarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-raigarh-assembly-constituency-result-2769956"
    },
    {
      id: 17,
      constituencyName: "Sarangarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-sarangarh-assembly-constituency-result-2769957"
    },
    {
      id: 18,
      constituencyName: "Kharsia",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kharsia-assembly-constituency-result-2769958"
    },
    {
      id: 19,
      constituencyName: "Dharamjaigarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dharamjaigarh-assembly-constituency-result-2769959"
    },
    {
      id: 20,
      constituencyName: "Rampur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-rampur-assembly-constituency-result-2769960"
    },
    {
      id: 21,
      constituencyName: "Korba",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-korba-assembly-constituency-result-2769961"
    },
    {
      id: 22,
      constituencyName: "Katghora",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-katghora-assembly-constituency-result-2769962"
    },
    {
      id: 23,
      constituencyName: "Pali-Tanakhar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-pali-tanakhar-assembly-constituency-result-2769963"
    },
    {
      id: 24,
      constituencyName: "Marwahi",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-marwahi-assembly-constituency-result-2769964"
    },
    {
      id: 25,
      constituencyName: "Kota",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kota-assembly-constituency-result-2769965"
    },
    {
      id: 26,
      constituencyName: "Lormi",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-lormi-assembly-constituency-result-2769966"
    },
    {
      id: 27,
      constituencyName: "Mungeli",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-mungeli-assembly-constituency-result-2769967"
    },
    {
      id: 28,
      constituencyName: "Takhatpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-takhatpur-assembly-constituency-result-2769968"
    },
    {
      id: 29,
      constituencyName: "Bilha",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bilha-assembly-constituency-result-2769969"
    },
    {
      id: 30,
      constituencyName: "Bilaspur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bilaspur-assembly-constituency-result-2769970"
    },
    {
      id: 31,
      constituencyName: "Beltara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-beltara-assembly-constituency-result-2769971"
    },
    {
      id: 32,
      constituencyName: "Masturi",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-masturi-assembly-constituency-result-2769972"
    },
    {
      id: 33,
      constituencyName: "Akaltara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-akaltara-assembly-constituency-result-2769973"
    },
    {
      id: 34,
      constituencyName: "Janjgir-Champa",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-janjgir-champa-assembly-constituency-result-2769974"
    },
    {
      id: 35,
      constituencyName: "Sakti",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-sakti-assembly-constituency-result-2769975"
    },
    {
      id: 36,
      constituencyName: "Chandrapur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-chandrapur-assembly-constituency-result-2769976"
    },
    {
      id: 37,
      constituencyName: "Jaijaipur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-jaijaipur-assembly-constituency-result-2769977"
    },
    {
      id: 38,
      constituencyName: "Pamgarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-pamgarh-assembly-constituency-result-2769978"
    },
    {
      id: 39,
      constituencyName: "Saraipali",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-saraipali-assembly-constituency-result-2769979"
    },
    {
      id: 40,
      constituencyName: "Basna",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-basna-assembly-constituency-result-2769980"
    },
    {
      id: 41,
      constituencyName: "Khallari",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-khallari-assembly-constituency-result-2769981"
    },
    {
      id: 42,
      constituencyName: "Mahasamund",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-mahasamund-assembly-constituency-result-2769982"
    },
    {
      id: 43,
      constituencyName: "Bilaigarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bilaigarh-assembly-constituency-result-2769983"
    },
    {
      id: 44,
      constituencyName: "Kasdol",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kasdol-assembly-constituency-result-2769984"
    },
    {
      id: 45,
      constituencyName: "Balodabazar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-balodabazar-assembly-constituency-result-2769985"
    },
    {
      id: 46,
      constituencyName: "Bhatapara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/hhattisgarh-assembly-elections-2023-bhatapara-assembly-constituency-result-2769986"
    },
    {
      id: 47,
      constituencyName: "Dharsiwa",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dharsiwa-assembly-constituency-result-2769987"
    },
    {
      id: 48,
      constituencyName: "Raipur City Gramin",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-raipur-city-gramin-assembly-constituency-result-2769988"
    },
    {
      id: 49,
      constituencyName: "Raipur City West",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-raipur-city-west-assembly-constituency-result-2769989"
    },
    {
      id: 50,
      constituencyName: "Raipur City North",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-raipur-city-north-assembly-constituency-result-2769990"
    },
    {
      id: 51,
      constituencyName: "Raipur City South",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-raipur-city-south-assembly-constituency-result-2769991"
    },
    {
      id: 52,
      constituencyName: "Arang",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-arang-assembly-constituency-result-2769992"
    },
    {
      id: 53,
      constituencyName: "Abhanpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-abhanpur-assembly-constituency-result-2769993"
    },
    {
      id: 54,
      constituencyName: "Rajim",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-rajim-assembly-constituency-result-2769994"
    },
    {
      id: 55,
      constituencyName: "Bindranawagarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bindranawagarh-assembly-constituency-result-2769995"
    },
    {
      id: 56,
      constituencyName: "Sihawa",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-sihawa-assembly-constituency-result-2769996"
    },
    {
      id: 57,
      constituencyName: "Kurud",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kurud-assembly-constituency-result-2769997"
    },
    {
      id: 58,
      constituencyName: "Dhamtari",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dhamtari-assembly-constituency-result-2769998"
    },
    {
      id: 59,
      constituencyName: "Sanjari Balod",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-sanjari-balod-assembly-constituency-result-2769999"
    },
    {
      id: 60,
      constituencyName: "Dondi Lohara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dondi-lohara-assembly-constituency-result-2770000"
    },
    {
      id: 61,
      constituencyName: "Gunderdehi",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-gunderdehi-assembly-constituency-result-2770001"
    },
    {
      id: 62,
      constituencyName: "Patan",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-patan-assembly-constituency-result-2770002"
    },
    {
      id: 63,
      constituencyName: "Durg Gramin",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-durg-gramin-assembly-constituency-result-2770003"
    },
    {
      id: 64,
      constituencyName: "Durg City",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-durg-city-assembly-constituency-result-2770004"
    },
    {
      id: 65,
      constituencyName: "Bhilai Nagar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bhilai-nagar-assembly-constituency-result-2770005"
    },
    {
      id: 66,
      constituencyName: "Vaishali Nagar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-vaishali-nagar-assembly-constituency-result-2770006"
    },
    {
      id: 67,
      constituencyName: "Ahiwara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-ahiwara-assembly-constituency-result-2770007"
    },
    {
      id: 68,
      constituencyName: "Saja",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-saja-assembly-constituency-result-2770008"
    },
    {
      id: 69,
      constituencyName: "Bemetara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bemetara-assembly-constituency-result-2770009"
    },
    {
      id: 70,
      constituencyName: "Navagarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-navagarh-assembly-constituency-result-2770010"
    },
    {
      id: 71,
      constituencyName: "Pandariya",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-pandariya-assembly-constituency-result-2770011"
    },
    {
      id: 72,
      constituencyName: "Kawardha",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kawardha-assembly-constituency-result-2770012"
    },
    {
      id: 73,
      constituencyName: "Khairagarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-khairagarh-assembly-constituency-result-2770013"
    },
    {
      id: 74,
      constituencyName: "Dongargarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dongargarh-assembly-constituency-result-2770014"
    },
    {
      id: 75,
      constituencyName: "Rajnandgaon",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-rajnandgaon-assembly-constituency-result-2770015"
    },
    {
      id: 76,
      constituencyName: "Dongargaon",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dongargaon-assembly-constituency-result-2770016"
    },
    {
      id: 77,
      constituencyName: "Khujji",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-khujji-assembly-constituency-result-2770017"
    },
    {
      id: 78,
      constituencyName: "Mohala-Manpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-mohala-manpur-assembly-constituency-result-2770018"
    },
    {
      id: 79,
      constituencyName: "Antagarh",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-antagarh-assembly-constituency-result-2770019"
    },
    {
      id: 80,
      constituencyName: "Bhanupratappur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bhanupratappur-assembly-constituency-result-2770020"
    },
    {
      id: 81,
      constituencyName: "Kanker",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kanker-assembly-constituency-result-2770021"
    },
    {
      id: 82,
      constituencyName: "Keshkal",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-keshkal-assembly-constituency-result-2770022"
    },
    {
      id: 83,
      constituencyName: "Kondagaon",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-kondagaon-assembly-constituency-result-2770023"
    },
    {
      id: 84,
      constituencyName: "Narayanpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-narayanpur-assembly-constituency-result-2770024"
    },
    {
      id: 85,
      constituencyName: "Bastar",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bastar-assembly-constituency-result-2770025"
    },
    {
      id: 86,
      constituencyName: "Jagdalpur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-jagdalpur-assembly-constituency-result-2770026"
    },
    {
      id: 87,
      constituencyName: "Chitrakot",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-chitrakot-assembly-constituency-result-2770027"
    },
    {
      id: 88,
      constituencyName: "Dantewara",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-dantewara-assembly-constituency-result-2770028"
    },
    {
      id: 89,
      constituencyName: "Bijapur",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-bijapur-assembly-constituency-result-2770029"
    },
    {
      id: 90,
      constituencyName: "Konta",
      primarySection: "Chhattisgarh (Elections)",
      secondarySection: "Chhattisgarh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/chhattisgarh/chhattisgarh-assembly-elections-2023-konta-assembly-constituency-result-2770030"
    }
  ],
  RAJASTHAN: [
    {
      id: 1,
      constituencyName: "Sadulshahar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sadulshahar-assembly-constituency-result-2770039"
    },
    {
      id: 2,
      constituencyName: "Ganganagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ganganagar-assembly-constituency-result-2770040"
    },
    {
      id: 3,
      constituencyName: "Karanpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-karanpur-assembly-constituency-result-2770041"
    },
    {
      id: 4,
      constituencyName: "Suratgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-suratgarh-assembly-constituency-result-2770042"
    },
    {
      id: 5,
      constituencyName: "Raisingh Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-raisingh-nagar-assembly-constituency-result-2770043"
    },
    {
      id: 6,
      constituencyName: "Anupgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-anupgarh-assembly-constituency-result-2770044"
    },
    {
      id: 7,
      constituencyName: "Sangaria",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sangaria-assembly-constituency-result-2770045"
    },
    {
      id: 8,
      constituencyName: "Hanumangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-hanumangarh-assembly-constituency-result-2770046"
    },
    {
      id: 9,
      constituencyName: "Pilibanga",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pilibanga-assembly-constituency-result-2770047"
    },
    {
      id: 10,
      constituencyName: "Nohar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nohar-assembly-constituency-result-2770048"
    },
    {
      id: 11,
      constituencyName: "Bhadra",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bhadra-assembly-constituency-result-2770049"
    },
    {
      id: 12,
      constituencyName: "Khajuwala",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khajuwala-assembly-constituency-result-2770050"
    },
    {
      id: 13,
      constituencyName: "Bikaner West",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bikaner-west-assembly-constituency-result-2770051"
    },
    {
      id: 14,
      constituencyName: "Bikaner East",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bikaner-east-assembly-constituency-result-2770052"
    },
    {
      id: 15,
      constituencyName: "Kolayat",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kolayat-assembly-constituency-result-2770053"
    },
    {
      id: 16,
      constituencyName: "Lunkaransar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-lunkaransar-assembly-constituency-result-2770054"
    },
    {
      id: 17,
      constituencyName: "Shree Dungargarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-shree-dungargarh-assembly-constituency-result-2770055"
    },
    {
      id: 18,
      constituencyName: "Nokha",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nokha-assembly-constituency-result-2770056"
    },
    {
      id: 19,
      constituencyName: "Sadulpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sadulpur-assembly-constituency-result-2770057"
    },
    {
      id: 20,
      constituencyName: "Taranagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-taranagar-assembly-constituency-result-2770058"
    },
    {
      id: 21,
      constituencyName: "Sardarshahar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sardarshahar-assembly-constituency-result-2770059"
    },
    {
      id: 22,
      constituencyName: "Churu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-churu-assembly-constituency-result-2770060"
    },
    {
      id: 23,
      constituencyName: "Ratangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ratangarh-assembly-constituency-result-2770061"
    },
    {
      id: 24,
      constituencyName: "Sujangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sujangarh-assembly-constituency-result-2770062"
    },
    {
      id: 25,
      constituencyName: "Pilani",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pilani-assembly-constituency-result-2770063"
    },
    {
      id: 26,
      constituencyName: "Surajgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-surajgarh-assembly-constituency-result-2770064"
    },
    {
      id: 27,
      constituencyName: "Jhunjhunu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jhunjhunu-assembly-constituency-result-2770065"
    },
    {
      id: 28,
      constituencyName: "Mandawa",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mandawa-assembly-constituency-result-2770066"
    },
    {
      id: 29,
      constituencyName: "Nawalgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nawalgarh-assembly-constituency-result-2770067"
    },
    {
      id: 30,
      constituencyName: "Udaipurwati",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-udaipurwati-assembly-constituency-result-2770068"
    },
    {
      id: 31,
      constituencyName: "Khetri",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khetri-assembly-constituency-result-2770069"
    },
    {
      id: 32,
      constituencyName: "Fatehpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-fatehpur-assembly-constituency-result-2770070"
    },
    {
      id: 33,
      constituencyName: "Lachhmangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-lachhmangarh-assembly-constituency-result-2770071"
    },
    {
      id: 34,
      constituencyName: "Dhod",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dhod-assembly-constituency-result-2770072"
    },
    {
      id: 35,
      constituencyName: "Sikar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sikar-assembly-constituency-result-2770073"
    },
    {
      id: 36,
      constituencyName: "Dantaramgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dantaramgarh-assembly-constituency-result-2770074"
    },
    {
      id: 37,
      constituencyName: "Khandela",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khandela-assembly-constituency-result-2770075"
    },
    {
      id: 38,
      constituencyName: "Neem Ka Thana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-neem-ka-thana-assembly-constituency-result-2770076"
    },
    {
      id: 39,
      constituencyName: "Srimadhopur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-srimadhopur-assembly-constituency-result-2770077"
    },
    {
      id: 40,
      constituencyName: "Kotputli",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kotputli-assembly-constituency-result-2770078"
    },
    {
      id: 41,
      constituencyName: "Viratnagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-viratnagar-assembly-constituency-result-2770079"
    },
    {
      id: 42,
      constituencyName: "Shahpura",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-shahpura-assembly-constituency-result-2770080"
    },
    {
      id: 43,
      constituencyName: "Chomu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chomu-assembly-constituency-result-2770081"
    },
    {
      id: 44,
      constituencyName: "Phulera",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-phulera-assembly-constituency-result-2770082"
    },
    {
      id: 45,
      constituencyName: "Dudu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dudu-assembly-constituency-result-2770083"
    },
    {
      id: 46,
      constituencyName: "Jhotwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jhotwara-assembly-constituency-result-2770084"
    },
    {
      id: 47,
      constituencyName: "Amber",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-amber-assembly-constituency-result-2770085"
    },
    {
      id: 48,
      constituencyName: "Jamwa Ramgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jamwa-ramgarh-assembly-constituency-result-2770086"
    },
    {
      id: 49,
      constituencyName: "Hawa Mahal",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-hawa-mahal-assembly-constituency-result-2770087"
    },
    {
      id: 50,
      constituencyName: "Vidhyadhar Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-vidhyadhar-nagar-assembly-constituency-result-2770088"
    },
    {
      id: 51,
      constituencyName: "Civil Lines",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-civil-lines-assembly-constituency-result-2770089"
    },
    {
      id: 52,
      constituencyName: "Kishan Pole",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kishan-pole-assembly-constituency-result-2770090"
    },
    {
      id: 53,
      constituencyName: "Adarsh Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-adarsh-nagar-assembly-constituency-result-2770091"
    },
    {
      id: 54,
      constituencyName: "Malviya Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-malviya-nagar-assembly-constituency-result-2770092"
    },
    {
      id: 55,
      constituencyName: "Sanganer",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sanganer-assembly-constituency-result-2770093"
    },
    {
      id: 56,
      constituencyName: "Bagru",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bagru-assembly-constituency-result-2770094"
    },
    {
      id: 57,
      constituencyName: "Bassi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bassi-assembly-constituency-result-2770095"
    },
    {
      id: 58,
      constituencyName: "Chaksu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chaksu-assembly-constituency-result-2770096"
    },
    {
      id: 59,
      constituencyName: "Tijara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-tijara-assembly-constituency-result-2770097"
    },
    {
      id: 60,
      constituencyName: "Kishangarh Bas",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kishangarh-bas-assembly-constituency-result-2770098"
    },
    {
      id: 61,
      constituencyName: "Mundawar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mundawar-assembly-constituency-result-2770099"
    },
    {
      id: 62,
      constituencyName: "Behror",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-behror-assembly-constituency-result-2770100"
    },
    {
      id: 63,
      constituencyName: "Bansur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bansur-assembly-constituency-result-2770101"
    },
    {
      id: 64,
      constituencyName: "Thanagazi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-thanagazi-assembly-constituency-result-2770102"
    },
    {
      id: 65,
      constituencyName: "Alwar Rural",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-alwar-rural-assembly-constituency-result-2770103"
    },
    {
      id: 66,
      constituencyName: "Alwar Urban",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-alwar-urban-assembly-constituency-result-2770104"
    },
    {
      id: 67,
      constituencyName: "Ramgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ramgarh-assembly-constituency-result-2770105"
    },
    {
      id: 68,
      constituencyName: "Rajgarh Laxmangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-rajgarh-laxmangarh-assembly-constituency-result-2770106"
    },
    {
      id: 69,
      constituencyName: "Kathumar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kathumar-assembly-constituency-result-2770107"
    },
    {
      id: 70,
      constituencyName: "Kaman",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kaman-assembly-constituency-result-2770108"
    },
    {
      id: 71,
      constituencyName: "Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nagar-assembly-constituency-result-2770109"
    },
    {
      id: 72,
      constituencyName: "Deeg-Kumher",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-deeg-kumher-assembly-constituency-result-2770110"
    },
    {
      id: 73,
      constituencyName: "Bharatpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bharatpur-assembly-constituency-result-2770111"
    },
    {
      id: 74,
      constituencyName: "Nadbai",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nadbai-assembly-constituency-result-2770112"
    },
    {
      id: 75,
      constituencyName: "Weir",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-weir-assembly-constituency-result-2770113"
    },
    {
      id: 76,
      constituencyName: "Bayana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bayana-assembly-constituency-result-2770114"
    },
    {
      id: 77,
      constituencyName: "Baseri",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-baseri-assembly-constituency-result-2770115"
    },
    {
      id: 78,
      constituencyName: "Bari",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bari-assembly-constituency-result-2770116"
    },
    {
      id: 79,
      constituencyName: "Dholpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dholpur-assembly-constituency-result-2770117"
    },
    {
      id: 80,
      constituencyName: "Rajakhera",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-rajakhera-assembly-constituency-result-2770118"
    },
    {
      id: 81,
      constituencyName: "Todabhim",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-todabhim-assembly-constituency-result-2770119"
    },
    {
      id: 82,
      constituencyName: "Hindaun",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-hindaun-assembly-constituency-result-2770120"
    },
    {
      id: 83,
      constituencyName: "Karauli",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-karauli-assembly-constituency-result-2770121"
    },
    {
      id: 84,
      constituencyName: "Sapotra",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sapotra-assembly-constituency-result-2770122"
    },
    {
      id: 85,
      constituencyName: "Bandikui",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bandikui-assembly-constituency-result-2770123"
    },
    {
      id: 86,
      constituencyName: "Mahuwa",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mahuwa-assembly-constituency-result-2770124"
    },
    {
      id: 87,
      constituencyName: "Sikrai",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sikrai-assembly-constituency-result-2770125"
    },
    {
      id: 88,
      constituencyName: "Dausa",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dausa-assembly-constituency-result-2770126"
    },
    {
      id: 89,
      constituencyName: "Lalsot",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-lalsot-assembly-constituency-result-2770127"
    },
    {
      id: 90,
      constituencyName: "Gangapur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-gangapur-assembly-constituency-result-2770128"
    },
    {
      id: 91,
      constituencyName: "Bamanwas",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bamanwas-assembly-constituency-result-2770129"
    },
    {
      id: 92,
      constituencyName: "Sawai Madhopur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sawai-madhopur-assembly-constituency-result-2770130"
    },
    {
      id: 93,
      constituencyName: "Khandar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khandar-assembly-constituency-result-2770131"
    },
    {
      id: 94,
      constituencyName: "Malpura",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-malpura-assembly-constituency-result-2770132"
    },
    {
      id: 95,
      constituencyName: "Niwai",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-niwai-assembly-constituency-result-2770133"
    },
    {
      id: 96,
      constituencyName: "Tonk",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-tonk-assembly-constituency-result-2770134"
    },
    {
      id: 97,
      constituencyName: "Deoli - Uniara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-deoli-uniara-assembly-constituency-result-2770135"
    },
    {
      id: 98,
      constituencyName: "Kishangarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kishangarh-assembly-constituency-result-2770136"
    },
    {
      id: 99,
      constituencyName: "Pushkar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pushkar-assembly-constituency-result-2770137"
    },
    {
      id: 100,
      constituencyName: "Ajmer North",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ajmer-north-assembly-constituency-result-2770138"
    },
    {
      id: 101,
      constituencyName: "Ajmer South",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ajmer-south-assembly-constituency-result-2770139"
    },
    {
      id: 102,
      constituencyName: "Nasirabad",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nasirabad-assembly-constituency-result-2770140"
    },
    {
      id: 103,
      constituencyName: "Beawar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-beawar-assembly-constituency-result-2770141"
    },
    {
      id: 104,
      constituencyName: "Masuda",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-masuda-assembly-constituency-result-2770142"
    },
    {
      id: 105,
      constituencyName: "Kekri",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kekri-assembly-constituency-result-2770143"
    },
    {
      id: 106,
      constituencyName: "Ladnun",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ladnun-assembly-constituency-result-2770144"
    },
    {
      id: 107,
      constituencyName: "Deedwana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-deedwana-assembly-constituency-result-2770145"
    },
    {
      id: 108,
      constituencyName: "Jayal",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jayal-assembly-constituency-result-2770146"
    },
    {
      id: 109,
      constituencyName: "Nagaur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nagaur-assembly-constituency-result-2770147"
    },
    {
      id: 110,
      constituencyName: "Khinwsar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khinwsar-assembly-constituency-result-2770148"
    },
    {
      id: 111,
      constituencyName: "Merta",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-merta-assembly-constituency-result-2770149"
    },
    {
      id: 112,
      constituencyName: "Degana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-degana-assembly-constituency-result-2770150"
    },
    {
      id: 113,
      constituencyName: "Makrana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-makrana-assembly-constituency-result-2770151"
    },
    {
      id: 114,
      constituencyName: "Parbatsar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-parbatsar-assembly-constituency-result-2770152"
    },
    {
      id: 115,
      constituencyName: "Nawan",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nawan-assembly-constituency-result-2770153"
    },
    {
      id: 116,
      constituencyName: "Jaitaran",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jaitaran-assembly-constituency-result-2770154"
    },
    {
      id: 117,
      constituencyName: "Sojat",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sojat-assembly-constituency-result-2770155"
    },
    {
      id: 118,
      constituencyName: "Pali",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pali-assembly-constituency-result-2770156"
    },
    {
      id: 119,
      constituencyName: "Marwar Junction",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-marwar-junction-assembly-constituency-result-2770157"
    },
    {
      id: 120,
      constituencyName: "Bali",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bali-assembly-constituency-result-2770158"
    },
    {
      id: 121,
      constituencyName: "Sumerpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sumerpur-assembly-constituency-result-2770159"
    },
    {
      id: 122,
      constituencyName: "Phalodi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-phalodi-assembly-constituency-result-2770160"
    },
    {
      id: 123,
      constituencyName: "Lohawat",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-lohawat-assembly-constituency-result-2770161"
    },
    {
      id: 124,
      constituencyName: "Shergarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-shergarh-assembly-constituency-result-2770162"
    },
    {
      id: 125,
      constituencyName: "Osian",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-osian-assembly-constituency-result-2770163"
    },
    {
      id: 126,
      constituencyName: "Bhopalgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bhopalgarh-assembly-constituency-result-2770164"
    },
    {
      id: 127,
      constituencyName: "Sardarpura",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sardarpura-assembly-constituency-result-2770165"
    },
    {
      id: 128,
      constituencyName: "Jodhpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jodhpur-assembly-constituency-result-2770166"
    },
    {
      id: 129,
      constituencyName: "Soorsagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-soorsagar-assembly-constituency-result-2770167"
    },
    {
      id: 130,
      constituencyName: "Luni",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-luni-assembly-constituency-result-2770168"
    },
    {
      id: 131,
      constituencyName: "Bilara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bilara-assembly-constituency-result-2770169"
    },
    {
      id: 132,
      constituencyName: "Jaisalmer",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jaisalmer-assembly-constituency-result-2770170"
    },
    {
      id: 133,
      constituencyName: "Pokaran",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pokaran-assembly-constituency-result-2770171"
    },
    {
      id: 134,
      constituencyName: "Sheo",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sheo-assembly-constituency-result-2770172"
    },
    {
      id: 135,
      constituencyName: "Barmer",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-barmer-assembly-constituency-result-2770173"
    },
    {
      id: 136,
      constituencyName: "Baytu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-baytu-assembly-constituency-result-2770174"
    },
    {
      id: 137,
      constituencyName: "Pachpadra",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pachpadra-assembly-constituency-result-2770175"
    },
    {
      id: 138,
      constituencyName: "Siwana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-siwana-assembly-constituency-result-2770176"
    },
    {
      id: 139,
      constituencyName: "Gudhamalani",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-gudhamalani-assembly-constituency-result-2770177"
    },
    {
      id: 140,
      constituencyName: "Chohtan",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chohtan-assembly-constituency-result-2770178"
    },
    {
      id: 141,
      constituencyName: "Ahore",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ahore-assembly-constituency-result-2770179"
    },
    {
      id: 142,
      constituencyName: "Jalore",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jalore-assembly-constituency-result-2770180"
    },
    {
      id: 143,
      constituencyName: "Bhinmal",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bhinmal-assembly-constituency-result-2770181"
    },
    {
      id: 144,
      constituencyName: "Sanchore",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sanchore-assembly-constituency-result-2770182"
    },
    {
      id: 145,
      constituencyName: "Raniwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-raniwara-assembly-constituency-result-2770183"
    },
    {
      id: 146,
      constituencyName: "Sirohi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sirohi-assembly-constituency-result-2770184"
    },
    {
      id: 147,
      constituencyName: "Pindwara Abu",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pindwara-abu-assembly-constituency-result-2770185"
    },
    {
      id: 148,
      constituencyName: "Reodar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-reodar-assembly-constituency-result-2770186"
    },
    {
      id: 149,
      constituencyName: "Gogunda",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-gogunda-assembly-constituency-result-2770187"
    },
    {
      id: 150,
      constituencyName: "Jhadol",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jhadol-assembly-constituency-result-2770188"
    },
    {
      id: 151,
      constituencyName: "Kherwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kherwara-assembly-constituency-result-2770189"
    },
    {
      id: 152,
      constituencyName: "Udaipur Rural",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-udaipur-rural-assembly-constituency-result-2770190"
    },
    {
      id: 153,
      constituencyName: "Udaipur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-udaipur-assembly-constituency-result-2770191"
    },
    {
      id: 154,
      constituencyName: "Mavli",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mavli-assembly-constituency-result-2770192"
    },
    {
      id: 155,
      constituencyName: "Vallabh Nagar",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-vallabh-nagar-assembly-constituency-result-2770193"
    },
    {
      id: 156,
      constituencyName: "Salumber",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-salumber-assembly-constituency-result-2770194"
    },
    {
      id: 157,
      constituencyName: "Dhariyawad",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dhariyawad-assembly-constituency-result-2770195"
    },
    {
      id: 158,
      constituencyName: "Dungarpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dungarpur-assembly-constituency-result-2770196"
    },
    {
      id: 159,
      constituencyName: "Aspur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-aspur-assembly-constituency-result-2770197"
    },
    {
      id: 160,
      constituencyName: "Sagwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sagwara-assembly-constituency-result-2770198"
    },
    {
      id: 161,
      constituencyName: "Chorasi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chorasi-assembly-constituency-result-2770199"
    },
    {
      id: 162,
      constituencyName: "Ghatol",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ghatol-assembly-constituency-result-2770200"
    },
    {
      id: 163,
      constituencyName: "Garhi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-garhi-assembly-constituency-result-2770201"
    },
    {
      id: 164,
      constituencyName: "Banswara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-banswara-assembly-constituency-result-2770202"
    },
    {
      id: 165,
      constituencyName: "Bagidora",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bagidora-assembly-constituency-result-2770203"
    },
    {
      id: 166,
      constituencyName: "Kushalgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kushalgarh-assembly-constituency-result-2770204"
    },
    {
      id: 167,
      constituencyName: "Kapasan",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kapasan-assembly-constituency-result-2770205"
    },
    {
      id: 168,
      constituencyName: "Begun",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-begun-assembly-constituency-result-2770206"
    },
    {
      id: 169,
      constituencyName: "Chittorgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chittorgarh-assembly-constituency-result-2770207"
    },
    {
      id: 170,
      constituencyName: "Nimbahera",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nimbahera-assembly-constituency-result-2770208"
    },
    {
      id: 171,
      constituencyName: "Bari Sadri",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bari-sadri-assembly-constituency-result-2770209"
    },
    {
      id: 172,
      constituencyName: "Pratapgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pratapgarh-assembly-constituency-result-2770210"
    },
    {
      id: 173,
      constituencyName: "Bhim",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bhim-assembly-constituency-result-2770211"
    },
    {
      id: 174,
      constituencyName: "Kumbhalgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kumbhalgarh-assembly-constituency-result-2770212"
    },
    {
      id: 175,
      constituencyName: "Rajsamand",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-rajsamand-assembly-constituency-result-2770213"
    },
    {
      id: 176,
      constituencyName: "Nathdwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-nathdwara-assembly-constituency-result-2770214"
    },
    {
      id: 177,
      constituencyName: "Asind",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-asind-assembly-constituency-result-2770215"
    },
    {
      id: 178,
      constituencyName: "Mandal",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mandal-assembly-constituency-result-2770216"
    },
    {
      id: 179,
      constituencyName: "Sahara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sahara-assembly-constituency-result-2770217"
    },
    {
      id: 180,
      constituencyName: "Bhilwara",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bhilwara-assembly-constituency-result-2770218"
    },
    {
      id: 181,
      constituencyName: "Shahpura",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-shahpura-assembly-constituency-result-2-2770219"
    },
    {
      id: 182,
      constituencyName: "Jahazpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jahazpur-assembly-constituency-result-2770220"
    },
    {
      id: 183,
      constituencyName: "Mandalgarh",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-mandalgarh-assembly-constituency-result-2770221"
    },
    {
      id: 184,
      constituencyName: "Hindoli",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-hindoli-assembly-constituency-result-2770222"
    },
    {
      id: 185,
      constituencyName: "Keshoraipatan",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-keshoraipatan-assembly-constituency-result-2770223"
    },
    {
      id: 186,
      constituencyName: "Bundi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-bundi-assembly-constituency-result-2770224"
    },
    {
      id: 187,
      constituencyName: "Pipalda",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-pipalda-assembly-constituency-result-2770225"
    },
    {
      id: 188,
      constituencyName: "Sangod",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-sangod-assembly-constituency-result-2770226"
    },
    {
      id: 189,
      constituencyName: "Kota North",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kota-north-assembly-constituency-result-2770227"
    },
    {
      id: 190,
      constituencyName: "Kota South",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kota-south-assembly-constituency-result-2770228"
    },
    {
      id: 191,
      constituencyName: "Ladpura",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ladpura-assembly-constituency-result-2770229"
    },
    {
      id: 192,
      constituencyName: "Ramganj Mandi",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-ramganj-mandi-assembly-constituency-result-2770230"
    },
    {
      id: 193,
      constituencyName: "Anta",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-anta-assembly-constituency-result-2770231"
    },
    {
      id: 194,
      constituencyName: "Kishanganj",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-kishanganj-assembly-constituency-result-2770232"
    },
    {
      id: 195,
      constituencyName: "Baran-Atru",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-baran-atru-assembly-constituency-result-2770233"
    },
    {
      id: 196,
      constituencyName: "Chhabra",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-chhabra-assembly-constituency-result-2770234"
    },
    {
      id: 197,
      constituencyName: "Dag",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-dag-assembly-constituency-result-2770235"
    },
    {
      id: 198,
      constituencyName: "Jhalrapatan",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-jhalrapatan-assembly-constituency-result-2770236"
    },
    {
      id: 199,
      constituencyName: "Khanpur",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-khanpur-assembly-constituency-result-2770237"
    },
    {
      id: 200,
      constituencyName: "Manohar Thana",
      primarySection: "Rajasthan (Elections)",
      secondarySection: "Rajasthan (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/rajasthan/rajasthan-assembly-elections-2023-manohar-thana-assembly-constituency-result-2770238"
    }
  ],
  TELANGANA: [
    {
      id: 1,
      constituencyName: "Sirpur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-sirpur-assembly-constituency-result-2770241"
    },
    {
      id: 2,
      constituencyName: "Chennur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-chennur-assembly-constituency-result-2770242"
    },
    {
      id: 3,
      constituencyName: "Bellampalli",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bellampalli-assembly-constituency-result-2770243"
    },
    {
      id: 4,
      constituencyName: "Mancherial",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-mancherial-assembly-constituency-result-2770244"
    },
    {
      id: 5,
      constituencyName: "Asifabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-asifabad-assembly-constituency-result-2770245"
    },
    {
      id: 6,
      constituencyName: "Khanapur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-khanapur-assembly-constituency-result-2770246"
    },
    {
      id: 7,
      constituencyName: "Adilabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-adilabad-assembly-constituency-result-2770247"
    },
    {
      id: 8,
      constituencyName: "Boath",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-boath-assembly-constituency-result-2770248"
    },
    {
      id: 9,
      constituencyName: "Nirmal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nirmal-assembly-constituency-result-2770249"
    },
    {
      id: 10,
      constituencyName: "Mudhole",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-mudhole-assembly-constituency-result-2770250"
    },
    {
      id: 11,
      constituencyName: "Armur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-armur-assembly-constituency-result-2770251"
    },
    {
      id: 12,
      constituencyName: "Bodhan",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bodhan-assembly-constituency-result-2770252"
    },
    {
      id: 13,
      constituencyName: "Jukkal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-jukkal-assembly-constituency-result-2770253"
    },
    {
      id: 14,
      constituencyName: "Banswada",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-banswada-assembly-constituency-result-2770254"
    },
    {
      id: 15,
      constituencyName: "Yellareddy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-yellareddy-assembly-constituency-result-2770255"
    },
    {
      id: 16,
      constituencyName: "Kamareddy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kamareddy-assembly-constituency-result-2770256"
    },
    {
      id: 17,
      constituencyName: "Nizamabad (Urban)",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nizamabad-urban-assembly-constituency-result-2770257"
    },
    {
      id: 18,
      constituencyName: "Nizamabad (Rural)",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nizamabad-rural-assembly-constituency-result-2770258"
    },
    {
      id: 19,
      constituencyName: "Balkonda",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-balkonda-assembly-constituency-result-2770259"
    },
    {
      id: 20,
      constituencyName: "Koratla",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-koratla-assembly-constituency-result-2770260"
    },
    {
      id: 21,
      constituencyName: "Jagtial",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-jagtial-assembly-constituency-result-2770261"
    },
    {
      id: 22,
      constituencyName: "Dharmapuri",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-dharmapuri-assembly-constituency-result-2770262"
    },
    {
      id: 23,
      constituencyName: "Ramagundam",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-ramagundam-assembly-constituency-result-2770263"
    },
    {
      id: 24,
      constituencyName: "Manthani",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-manthani-assembly-constituency-result-2770264"
    },
    {
      id: 25,
      constituencyName: "Peddapalle",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-peddapalle-assembly-constituency-result-2770265"
    },
    {
      id: 26,
      constituencyName: "Karimnagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-karimnagar-assembly-constituency-result-2770266"
    },
    {
      id: 27,
      constituencyName: "Choppadandi",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-choppadandi-assembly-constituency-result-2770267"
    },
    {
      id: 28,
      constituencyName: "Vemulawada",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-vemulawada-assembly-constituency-result-2770268"
    },
    {
      id: 29,
      constituencyName: "Sircilla",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-sircilla-assembly-constituency-result-2770269"
    },
    {
      id: 30,
      constituencyName: "Manakondur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-manakondur-assembly-constituency-result-2770270"
    },
    {
      id: 31,
      constituencyName: "Huzurabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-huzurabad-assembly-constituency-result-2770271"
    },
    {
      id: 32,
      constituencyName: "Husnabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-husnabad-assembly-constituency-result-2770272"
    },
    {
      id: 33,
      constituencyName: "Siddipet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-siddipet-assembly-constituency-result-2770273"
    },
    {
      id: 34,
      constituencyName: "Medak",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-medak-assembly-constituency-result-2770274"
    },
    {
      id: 35,
      constituencyName: "Narayankhed",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-narayankhed-assembly-constituency-result-2770275"
    },
    {
      id: 36,
      constituencyName: "Andole",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-andole-assembly-constituency-result-2770276"
    },
    {
      id: 37,
      constituencyName: "Narsapur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-narsapur-assembly-constituency-result-2770277"
    },
    {
      id: 38,
      constituencyName: "Zahirabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-zahirabad-assembly-constituency-result-2770278"
    },
    {
      id: 39,
      constituencyName: "Sangareddy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-sangareddy-assembly-constituency-result-2770279"
    },
    {
      id: 40,
      constituencyName: "Patancheru",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-patancheru-assembly-constituency-result-2770280"
    },
    {
      id: 41,
      constituencyName: "Dubbak",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-dubbak-assembly-constituency-result-2770281"
    },
    {
      id: 42,
      constituencyName: "Gajwel",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-gajwel-assembly-constituency-result-2770282"
    },
    {
      id: 43,
      constituencyName: "Medchal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-medchal-assembly-constituency-result-2770283"
    },
    {
      id: 44,
      constituencyName: "Malkajgiri",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-malkajgiri-assembly-constituency-result-2770284"
    },
    {
      id: 45,
      constituencyName: "Quthbullapur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-quthbullapur-assembly-constituency-result-2770285"
    },
    {
      id: 46,
      constituencyName: "Kukatpally",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kukatpally-assembly-constituency-result-2770286"
    },
    {
      id: 47,
      constituencyName: "Uppal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-uppal-assembly-constituency-result-2770287"
    },
    {
      id: 48,
      constituencyName: "Ibrahimpatnam",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-ibrahimpatnam-assembly-constituency-result-2770288"
    },
    {
      id: 49,
      constituencyName: "Lal Bahadur Nagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-lal-bahadur-nagar-assembly-constituency-result-2770289"
    },
    {
      id: 50,
      constituencyName: "Maheshwaram",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-maheshwaram-assembly-constituency-result-2770290"
    },
    {
      id: 51,
      constituencyName: "Rajendranagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-rajendranagar-assembly-constituency-result-2770291"
    },
    {
      id: 52,
      constituencyName: "Serilingampally",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-serilingampally-assembly-constituency-result-2770292"
    },
    {
      id: 53,
      constituencyName: "Chevella",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-chevella-assembly-constituency-result-2770293"
    },
    {
      id: 54,
      constituencyName: "Pargi",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-pargi-assembly-constituency-result-2770294"
    },
    {
      id: 55,
      constituencyName: "Vikarabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-vikarabad-assembly-constituency-result-2770295"
    },
    {
      id: 56,
      constituencyName: "Tandur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-tandur-assembly-constituency-result-2770296"
    },
    {
      id: 57,
      constituencyName: "Musheerabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-musheerabad-assembly-constituency-result-2770297"
    },
    {
      id: 58,
      constituencyName: "Malakpet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-malakpet-assembly-constituency-result-2770298"
    },
    {
      id: 59,
      constituencyName: "Amberpet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-amberpet-assembly-constituency-result-2770299"
    },
    {
      id: 60,
      constituencyName: "Khairatabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-khairatabad-assembly-constituency-result-2770300"
    },
    {
      id: 61,
      constituencyName: "Jubilee Hills",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-jubilee-hills-assembly-constituency-result-2770301"
    },
    {
      id: 62,
      constituencyName: "Sanath Nagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-sanath-nagar-assembly-constituency-result-2770302"
    },
    {
      id: 63,
      constituencyName: "Nampalli",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nampally-assembly-constituency-result-2770303"
    },
    {
      id: 64,
      constituencyName: "Karwan",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-karwan-assembly-constituency-result-2770304"
    },
    {
      id: 65,
      constituencyName: "Goshamahal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-goshamahal-assembly-constituency-result-2770305"
    },
    {
      id: 66,
      constituencyName: "Charminar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-charminar-assembly-constituency-result-2770306"
    },
    {
      id: 67,
      constituencyName: "Chandrayangutta",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-chandrayangutta-assembly-constituency-result-2770307"
    },
    {
      id: 68,
      constituencyName: "Yakutpura",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-yakutpura-assembly-constituency-result-2770308"
    },
    {
      id: 69,
      constituencyName: "Bahadurpura",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bahadurpura-assembly-constituency-result-2770309"
    },
    {
      id: 70,
      constituencyName: "Secunderabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-secunderabad-assembly-constituency-result-2770310"
    },
    {
      id: 71,
      constituencyName: "Secunderabad Cantt.",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-secunderabad-cantt-assembly-constituency-result-2770311"
    },
    {
      id: 72,
      constituencyName: "Kodangal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kodangal-assembly-constituency-result-2770312"
    },
    {
      id: 73,
      constituencyName: "Narayanpet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-narayanpet-assembly-constituency-result-2770313"
    },
    {
      id: 74,
      constituencyName: "Mahbubnagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-mahbubnagar-assembly-constituency-result-2770314"
    },
    {
      id: 75,
      constituencyName: "Jadcherla",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-jadcherla-assembly-constituency-result-2770315"
    },
    {
      id: 76,
      constituencyName: "Devarkadra",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-devarkadra-assembly-constituency-result-2770316"
    },
    {
      id: 77,
      constituencyName: "Makthal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-makthal-assembly-constituency-result-2770317"
    },
    {
      id: 78,
      constituencyName: "Wanaparthy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-wanaparthy-assembly-constituency-result-2770318"
    },
    {
      id: 79,
      constituencyName: "Gadwal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-gadwal-assembly-constituency-result-2770319"
    },
    {
      id: 80,
      constituencyName: "Alampur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-alampur-assembly-constituency-result-2770320"
    },
    {
      id: 81,
      constituencyName: "Nagarkurnool",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nagarkurnool-assembly-constituency-result-2770321"
    },
    {
      id: 82,
      constituencyName: "Achampet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-achampet-assembly-constituency-result-2770322"
    },
    {
      id: 83,
      constituencyName: "Kalwakurthy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kalwakurthy-assembly-constituency-result-2770323"
    },
    {
      id: 84,
      constituencyName: "Shadnagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-shadnagar-assembly-constituency-result-2770324"
    },
    {
      id: 85,
      constituencyName: "Kollapur",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kollapur-assembly-constituency-result-2770325"
    },
    {
      id: 86,
      constituencyName: "Devarakonda",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-devarakonda-assembly-constituency-result-2770326"
    },
    {
      id: 87,
      constituencyName: "Nagarjuna Sagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nagarjuna-sagar-assembly-constituency-result-2770327"
    },
    {
      id: 88,
      constituencyName: "Miryalguda",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-miryalguda-assembly-constituency-result-2770328"
    },
    {
      id: 89,
      constituencyName: "Huzurnagar",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-huzurnagar-assembly-constituency-result-2770329"
    },
    {
      id: 90,
      constituencyName: "Kodad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kodad-assembly-constituency-result-2770330"
    },
    {
      id: 91,
      constituencyName: "Suryapet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-suryapet-assembly-constituency-result-2770331"
    },
    {
      id: 92,
      constituencyName: "Nalgonda",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nalgonda-assembly-constituency-result-2770332"
    },
    {
      id: 93,
      constituencyName: "Munugode",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-munugode-assembly-constituency-result-2770333"
    },
    {
      id: 94,
      constituencyName: "Bhongir",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bhongir-assembly-constituency-result-2770334"
    },
    {
      id: 95,
      constituencyName: "Nakrekal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-nakrekal-assembly-constituency-result-2770335"
    },
    {
      id: 96,
      constituencyName: "Thungathurthy",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-thungathurthy-assembly-constituency-result-2770336"
    },
    {
      id: 97,
      constituencyName: "Alair",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-alair-assembly-constituency-result-2770337"
    },
    {
      id: 98,
      constituencyName: "Jangoan",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-jangoan-assembly-constituency-result-2770338"
    },
    {
      id: 99,
      constituencyName: "Ghanpur (Station)",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-ghanpur-station-assembly-constituency-result-2770339"
    },
    {
      id: 100,
      constituencyName: "Palakurthi",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-palakurthi-assembly-constituency-result-2770340"
    },
    {
      id: 101,
      constituencyName: "Dornakal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-dornakal-assembly-constituency-result-2770341"
    },
    {
      id: 102,
      constituencyName: "Mahabubabad",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-mahabubabad-assembly-constituency-result-2770342"
    },
    {
      id: 103,
      constituencyName: "Narsampet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-narsampet-assembly-constituency-result-2770343"
    },
    {
      id: 104,
      constituencyName: "Parkal",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-parkal-assembly-constituency-result-2770344"
    },
    {
      id: 105,
      constituencyName: "Warangal West",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-warangal-west-assembly-constituency-result-2770345"
    },
    {
      id: 106,
      constituencyName: "Warangal East",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-warangal-east-assembly-constituency-result-2770346"
    },
    {
      id: 107,
      constituencyName: "Wardhanapet",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-wardhanapet-assembly-constituency-result-2770347"
    },
    {
      id: 108,
      constituencyName: "Bhupalpalle",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bhupalpalle-assembly-constituency-result-2770348"
    },
    {
      id: 109,
      constituencyName: "Mulug",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-mulug-assembly-constituency-result-2770349"
    },
    {
      id: 110,
      constituencyName: "Pinapaka",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-pinapaka-assembly-constituency-result-2770350"
    },
    {
      id: 111,
      constituencyName: "Yellandu",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-yellandu-assembly-constituency-result-2770351"
    },
    {
      id: 112,
      constituencyName: "Khammam",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-khammam-assembly-constituency-result-2770352"
    },
    {
      id: 113,
      constituencyName: "Palair",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-palair-assembly-constituency-result-2770353"
    },
    {
      id: 114,
      constituencyName: "Madhira",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-madhira-assembly-constituency-result-2770354"
    },
    {
      id: 115,
      constituencyName: "Wyra",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-wyra-assembly-constituency-result-2770355"
    },
    {
      id: 116,
      constituencyName: "Sathupalli",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-sathupalli-assembly-constituency-result-2770356"
    },
    {
      id: 117,
      constituencyName: "Kothagudem",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-kothagudem-assembly-constituency-result-2770357"
    },
    {
      id: 118,
      constituencyName: "Aswaraopeta",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-aswaraopeta-assembly-constituency-result-2770358"
    },
    {
      id: 119,
      constituencyName: "Bhadrachalam",
      primarySection: "Telangana (Elections)",
      secondarySection: "Telangana (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/telangana/telangana-assembly-elections-2023-bhadrachalam-assembly-constituency-result-2770359"
    }
  ],
  "MADHYA PRADESH": [
    {
      id: 1,
      constituencyName: "Sheopur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sheopur-assembly-constituency-result-2770362"
    },
    {
      id: 2,
      constituencyName: "Vijaypur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-vijaypur-assembly-constituency-result-2770363"
    },
    {
      id: 3,
      constituencyName: "Sabalagadh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sabalgarh-assembly-constituency-result-2770364"
    },
    {
      id: 4,
      constituencyName: "Jaura",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jaura-assembly-constituency-result-2770365"
    },
    {
      id: 5,
      constituencyName: "Sumaoli",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sumaoli-assembly-constituency-result-2770366"
    },
    {
      id: 6,
      constituencyName: "Morena",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-morena-assembly-constituency-result-2770367"
    },
    {
      id: 7,
      constituencyName: "Dimani",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dimani-assembly-constituency-result-2770368"
    },
    {
      id: 8,
      constituencyName: "Ambah",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ambah-assembly-constituency-result-2770369"
    },
    {
      id: 9,
      constituencyName: "Ater",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ater-assembly-constituency-result-2770370"
    },
    {
      id: 10,
      constituencyName: "Bhind",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhind-assembly-constituency-result-2770371"
    },
    {
      id: 11,
      constituencyName: "Lahar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-lahar-assembly-constituency-result-2770372"
    },
    {
      id: 12,
      constituencyName: "Mehgaon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mehgaon-assembly-constituency-result-2770373"
    },
    {
      id: 13,
      constituencyName: "Gohad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gohad-assembly-constituency-result-2770374"
    },
    {
      id: 14,
      constituencyName: "Gwalior Rural",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gwalior-rural-assembly-constituency-result-2770375"
    },
    {
      id: 15,
      constituencyName: "Gwalior",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gwalior-assembly-constituency-result-2770376"
    },
    {
      id: 16,
      constituencyName: "Gwalior East",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gwalior-east-assembly-constituency-result-2770377"
    },
    {
      id: 17,
      constituencyName: "Gwalior South",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gwalior-south-assembly-constituency-result-2770378"
    },
    {
      id: 18,
      constituencyName: "Bhitarwar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhitarwar-assembly-constituency-result-2770379"
    },
    {
      id: 19,
      constituencyName: "Dabara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dabara-assembly-constituency-result-2770380"
    },
    {
      id: 20,
      constituencyName: "Sewda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sewda-assembly-constituency-result-2770381"
    },
    {
      id: 21,
      constituencyName: "Bhander",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhander-assembly-constituency-result-2770382"
    },
    {
      id: 22,
      constituencyName: "Datia",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-datia-assembly-constituency-result-2770383"
    },
    {
      id: 23,
      constituencyName: "Karera",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-karera-assembly-constituency-result-2770384"
    },
    {
      id: 24,
      constituencyName: "Pohari",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pohari-assembly-constituency-result-2770385"
    },
    {
      id: 25,
      constituencyName: "Shivpuri",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-shivpuri-assembly-constituency-result-2770386"
    },
    {
      id: 26,
      constituencyName: "Pichhore",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pichhore-assembly-constituency-result-2770387"
    },
    {
      id: 27,
      constituencyName: "Kolaras",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kolaras-assembly-constituency-result-2770388"
    },
    {
      id: 28,
      constituencyName: "Bamori",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bamori-assembly-constituency-result-2770389"
    },
    {
      id: 29,
      constituencyName: "Guna",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-guna-assembly-constituency-result-2770390"
    },
    {
      id: 30,
      constituencyName: "Chachoda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chachoda-assembly-constituency-result-2770391"
    },
    {
      id: 31,
      constituencyName: "Raghogarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-raghogarh-assembly-constituency-result-2770392"
    },
    {
      id: 32,
      constituencyName: "Ashok Nagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ashok-nagar-assembly-constituency-result-2770393"
    },
    {
      id: 33,
      constituencyName: "Chanderi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chanderi-assembly-constituency-result-2770394"
    },
    {
      id: 34,
      constituencyName: "Mungaoli",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mungaoli-assembly-constituency-result-2770395"
    },
    {
      id: 35,
      constituencyName: "Bina",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bina-assembly-constituency-result-2770396"
    },
    {
      id: 36,
      constituencyName: "Khurai",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khurai-assembly-constituency-result-2770397"
    },
    {
      id: 37,
      constituencyName: "Surkhi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-surkhi-assembly-constituency-result-2770398"
    },
    {
      id: 38,
      constituencyName: "Deori",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-deori-assembly-constituency-result-2770399"
    },
    {
      id: 39,
      constituencyName: "Rehli",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rehli-assembly-constituency-result-2770400"
    },
    {
      id: 40,
      constituencyName: "Naryawali",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-naryawali-assembly-constituency-result-2770401"
    },
    {
      id: 41,
      constituencyName: "Sagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sagar-assembly-constituency-result-2770402"
    },
    {
      id: 42,
      constituencyName: "Banda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-banda-assembly-constituency-result-2770403"
    },
    {
      id: 43,
      constituencyName: "Tikamgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-tikamgarh-assembly-constituency-result-2770404"
    },
    {
      id: 44,
      constituencyName: "Jatara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jatara-assembly-constituency-result-2770405"
    },
    {
      id: 45,
      constituencyName: "Prithvipur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-prithvipur-assembly-constituency-result-2770406"
    },
    {
      id: 46,
      constituencyName: "Niwari",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-niwari-assembly-constituency-result-2770407"
    },
    {
      id: 47,
      constituencyName: "Khargapur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khargapur-assembly-constituency-result-2770408"
    },
    {
      id: 48,
      constituencyName: "Maharajpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-maharajpur-assembly-constituency-result-2770409"
    },
    {
      id: 49,
      constituencyName: "Chandla",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chandla-assembly-constituency-result-2770410"
    },
    {
      id: 50,
      constituencyName: "Rajnagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rajnagar-assembly-constituency-result-2770411"
    },
    {
      id: 51,
      constituencyName: "Chhatarpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chhatarpur-assembly-constituency-result-2770412"
    },
    {
      id: 52,
      constituencyName: "Bijawar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bijawar-assembly-constituency-result-2770413"
    },
    {
      id: 53,
      constituencyName: "Malhara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-malhara-assembly-constituency-result-2770414"
    },
    {
      id: 54,
      constituencyName: "Pathariya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pathariya-assembly-constituency-result-2770415"
    },
    {
      id: 55,
      constituencyName: "Damoh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-damoh-assembly-constituency-result-2770416"
    },
    {
      id: 56,
      constituencyName: "Jabera",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jabera-assembly-constituency-result-2770417"
    },
    {
      id: 57,
      constituencyName: "Hatta",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-hatta-assembly-constituency-result-2770418"
    },
    {
      id: 58,
      constituencyName: "Pawai",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pawai-assembly-constituency-result-2770419"
    },
    {
      id: 59,
      constituencyName: "Gunnour",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gunnour-assembly-constituency-result-2770420"
    },
    {
      id: 60,
      constituencyName: "Panna",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-panna-assembly-constituency-result-2770421"
    },
    {
      id: 61,
      constituencyName: "Chitrakoot",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chitrakoot-assembly-constituency-result-2770422"
    },
    {
      id: 62,
      constituencyName: "Raigaon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-raigaon-assembly-constituency-result-2770423"
    },
    {
      id: 63,
      constituencyName: "Satna",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-satna-assembly-constituency-result-2770424"
    },
    {
      id: 64,
      constituencyName: "Nagod",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-nagod-assembly-constituency-result-2770425"
    },
    {
      id: 65,
      constituencyName: "Maihar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-maihar-assembly-constituency-result-2770426"
    },
    {
      id: 66,
      constituencyName: "Amarpatan",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-amarpatan-assembly-constituency-result-2770427"
    },
    {
      id: 67,
      constituencyName: "Rampur Baghelan",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rampur-baghelan-assembly-constituency-result-2770428"
    },
    {
      id: 68,
      constituencyName: "Sirmour",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sirmour-assembly-constituency-result-2770429"
    },
    {
      id: 69,
      constituencyName: "Semariya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-semariya-assembly-constituency-result-2770430"
    },
    {
      id: 70,
      constituencyName: "Teonthar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-teonthar-assembly-constituency-result-2770431"
    },
    {
      id: 71,
      constituencyName: "Mauganj",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mauganj-assembly-constituency-result-2770432"
    },
    {
      id: 72,
      constituencyName: "Deotalab",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-deotalab-assembly-constituency-result-2770433"
    },
    {
      id: 73,
      constituencyName: "Mangawan",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mangawan-assembly-constituency-result-2770434"
    },
    {
      id: 74,
      constituencyName: "Rewa",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rewa-assembly-constituency-result-2770435"
    },
    {
      id: 75,
      constituencyName: "Gurh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gurh-assembly-constituency-result-2770436"
    },
    {
      id: 76,
      constituencyName: "Churhat",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-churhat-assembly-constituency-result-2770437"
    },
    {
      id: 77,
      constituencyName: "Sidhi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sidhi-assembly-constituency-result-2770438"
    },
    {
      id: 78,
      constituencyName: "Sihawal",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sihawal-assembly-constituency-result-2770439"
    },
    {
      id: 79,
      constituencyName: "Chitrangi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chitrangi-assembly-constituency-result-2770440"
    },
    {
      id: 80,
      constituencyName: "Singarouli",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-singarouli-assembly-constituency-result-2770441"
    },
    {
      id: 81,
      constituencyName: "Devsar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-devsar-assembly-constituency-result-2770442"
    },
    {
      id: 82,
      constituencyName: "Dhouhani",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dhouhani-assembly-constituency-result-2770443"
    },
    {
      id: 83,
      constituencyName: "Beohari",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-beohari-assembly-constituency-result-2770444"
    },
    {
      id: 84,
      constituencyName: "Jaisinghnagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jaisinghnagar-assembly-constituency-result-2770445"
    },
    {
      id: 85,
      constituencyName: "Jaitpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jaitpur-assembly-constituency-result-2770446"
    },
    {
      id: 86,
      constituencyName: "Kotama",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kotama-assembly-constituency-result-2770447"
    },
    {
      id: 87,
      constituencyName: "Anuppur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-anuppur-assembly-constituency-result-2770448"
    },
    {
      id: 88,
      constituencyName: "Pusprajgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pusprajgarh-assembly-constituency-result-2770449"
    },
    {
      id: 89,
      constituencyName: "Bandhavgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bandhavgarh-assembly-constituency-result-2770450"
    },
    {
      id: 90,
      constituencyName: "Manpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-manpur-assembly-constituency-result-2770451"
    },
    {
      id: 91,
      constituencyName: "Badwara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-badwara-assembly-constituency-result-2770452"
    },
    {
      id: 92,
      constituencyName: "Vijayraghavgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-vijayraghavgarh-assembly-constituency-result-2770453"
    },
    {
      id: 93,
      constituencyName: "Mudwara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mudwara-assembly-constituency-result-2770454"
    },
    {
      id: 94,
      constituencyName: "Bahoriband",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bahoriband-assembly-constituency-result-2770455"
    },
    {
      id: 95,
      constituencyName: "Patan",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-patan-assembly-constituency-result-2770456"
    },
    {
      id: 96,
      constituencyName: "Bargi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bargi-assembly-constituency-result-2770457"
    },
    {
      id: 97,
      constituencyName: "Jabalpur East",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jabalpur-east-assembly-constituency-result-2770458"
    },
    {
      id: 98,
      constituencyName: "Jabalpur North",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jabalpur-north-assembly-constituency-result-2770459"
    },
    {
      id: 99,
      constituencyName: "Jabalpur Cantt",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jabalpur-cantt-assembly-constituency-result-2770460"
    },
    {
      id: 100,
      constituencyName: "Jabalpur West",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jabalpur-west-assembly-constituency-result-2770461"
    },
    {
      id: 101,
      constituencyName: "Panagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-panagar-assembly-constituency-result-2770462"
    },
    {
      id: 102,
      constituencyName: "Sihora",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sihora-assembly-constituency-result-2770463"
    },
    {
      id: 103,
      constituencyName: "Shahpura",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-shahpura-assembly-constituency-result-2770464"
    },
    {
      id: 104,
      constituencyName: "Dindori",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dindori-assembly-constituency-result-2770465"
    },
    {
      id: 105,
      constituencyName: "Bichhiya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bichhiya-assembly-constituency-result-2770466"
    },
    {
      id: 106,
      constituencyName: "Niwas",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-niwas-assembly-constituency-result-2770467"
    },
    {
      id: 107,
      constituencyName: "Mandla",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mandla-assembly-constituency-result-2770468"
    },
    {
      id: 108,
      constituencyName: "Baihar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-baihar-assembly-constituency-result-2770469"
    },
    {
      id: 109,
      constituencyName: "Lanji",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-lanji-assembly-constituency-result-2770470"
    },
    {
      id: 110,
      constituencyName: "Paraswada",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-paraswada-assembly-constituency-result-2770471"
    },
    {
      id: 111,
      constituencyName: "Balaghat",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-balaghat-assembly-constituency-result-2770472"
    },
    {
      id: 112,
      constituencyName: "Waraseoni",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-waraseoni-assembly-constituency-result-2770473"
    },
    {
      id: 113,
      constituencyName: "Katangi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-katangi-assembly-constituency-result-2770474"
    },
    {
      id: 114,
      constituencyName: "Barghat",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-barghat-assembly-constituency-result-2770475"
    },
    {
      id: 115,
      constituencyName: "Seoni",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-seoni-assembly-constituency-result-2770476"
    },
    {
      id: 116,
      constituencyName: "Keolari",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-keolari-assembly-constituency-result-2770477"
    },
    {
      id: 117,
      constituencyName: "Lakhanadon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-lakhanadon-assembly-constituency-result-2770478"
    },
    {
      id: 118,
      constituencyName: "Gotegaon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gotegaon-assembly-constituency-result-2770479"
    },
    {
      id: 119,
      constituencyName: "Narsingpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-narsingpur-assembly-constituency-result-2770480"
    },
    {
      id: 120,
      constituencyName: "Tendukheda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-tendukheda-assembly-constituency-result-2770481"
    },
    {
      id: 121,
      constituencyName: "Gadarwara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gadarwara-assembly-constituency-result-2770482"
    },
    {
      id: 122,
      constituencyName: "Junnardeo",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-junnardeo-assembly-constituency-result-2770483"
    },
    {
      id: 123,
      constituencyName: "Amarwara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-amarwara-assembly-constituency-result-2770484"
    },
    {
      id: 124,
      constituencyName: "Chourai",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chourai-assembly-constituency-result-2770485"
    },
    {
      id: 125,
      constituencyName: "Sausar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sausar-assembly-constituency-result-2770486"
    },
    {
      id: 126,
      constituencyName: "Chhindwara",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-chhindwara-assembly-constituency-result-2770487"
    },
    {
      id: 127,
      constituencyName: "Parasiya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-parasiya-assembly-constituency-result-2770488"
    },
    {
      id: 128,
      constituencyName: "Pandhurna",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pandhurna-assembly-constituency-result-2770489"
    },
    {
      id: 129,
      constituencyName: "Multai",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-multai-assembly-constituency-result-2770490"
    },
    {
      id: 130,
      constituencyName: "Amla",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-amla-assembly-constituency-result-2770491"
    },
    {
      id: 131,
      constituencyName: "Betul",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-betul-assembly-constituency-result-2770492"
    },
    {
      id: 132,
      constituencyName: "Ghodadongri",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ghodadongri-assembly-constituency-result-2770493"
    },
    {
      id: 133,
      constituencyName: "Bhainsdehi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhainsdehi-assembly-constituency-result-2770494"
    },
    {
      id: 134,
      constituencyName: "Timarni",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-timarni-assembly-constituency-result-2770495"
    },
    {
      id: 135,
      constituencyName: "Harda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-harda-assembly-constituency-result-2770496"
    },
    {
      id: 136,
      constituencyName: "Seoni Malwa",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-seoni-malwa-assembly-constituency-result-2770497"
    },
    {
      id: 137,
      constituencyName: "Hoshangabad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-hoshangabad-assembly-constituency-result-2770498"
    },
    {
      id: 138,
      constituencyName: "Sohagpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sohagpur-assembly-constituency-result-2770499"
    },
    {
      id: 139,
      constituencyName: "Pipariya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pipariya-assembly-constituency-result-2770500"
    },
    {
      id: 140,
      constituencyName: "Udaipura",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-udaipura-assembly-constituency-result-2770501"
    },
    {
      id: 141,
      constituencyName: "Bhojpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhojpur-assembly-constituency-result-2770502"
    },
    {
      id: 142,
      constituencyName: "Sanchi (SC)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sanchi-sc-assembly-constituency-result-2770503"
    },
    {
      id: 143,
      constituencyName: "Silwani",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-silwani-assembly-constituency-result-2770504"
    },
    {
      id: 144,
      constituencyName: "Vidisha",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-vidisha-assembly-constituency-result-2770505"
    },
    {
      id: 145,
      constituencyName: "Basoda",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-basoda-assembly-constituency-result-2770506"
    },
    {
      id: 146,
      constituencyName: "Kurwai (SC)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kurwai-sc-assembly-constituency-result-2770507"
    },
    {
      id: 147,
      constituencyName: "Sironj",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sironj-assembly-constituency-result-2770508"
    },
    {
      id: 148,
      constituencyName: "Shamshabad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-shamshabad-assembly-constituency-result-2770509"
    },
    {
      id: 149,
      constituencyName: "Berasia",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-berasia-assembly-constituency-result-2770510"
    },
    {
      id: 150,
      constituencyName: "Bhopal Uttar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhopal-uttar-assembly-constituency-result-2770511"
    },
    {
      id: 151,
      constituencyName: "Narela",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-narela-assembly-constituency-result-2770512"
    },
    {
      id: 152,
      constituencyName: "Bhopal Dakshina-Pashchim",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhopal-dakshina-pashchim-assembly-constituency-result-2770513"
    },
    {
      id: 153,
      constituencyName: "Bhopal Madhya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhopal-madhya-assembly-constituency-result-2770514"
    },
    {
      id: 154,
      constituencyName: "Govindpura",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-govindpura-assembly-constituency-result-2770515"
    },
    {
      id: 155,
      constituencyName: "Huzur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-huzur-assembly-constituency-result-2770516"
    },
    {
      id: 156,
      constituencyName: "Budhni",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-budhni-assembly-constituency-result-2770517"
    },
    {
      id: 157,
      constituencyName: "Ashta",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ashta-assembly-constituency-result-2770518"
    },
    {
      id: 158,
      constituencyName: "Ichhawar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ichhawar-assembly-constituency-result-2770519"
    },
    {
      id: 159,
      constituencyName: "Sehore",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sehore-assembly-constituency-result-2770520"
    },
    {
      id: 160,
      constituencyName: "Narsinghgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-narsinghgarh-assembly-constituency-result-2770521"
    },
    {
      id: 161,
      constituencyName: "Biaora",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-biaora-assembly-constituency-result-2770522"
    },
    {
      id: 162,
      constituencyName: "Rajgarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rajgarh-assembly-constituency-result-2770523"
    },
    {
      id: 163,
      constituencyName: "Khilchipur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khilchipur-assembly-constituency-result-2770524"
    },
    {
      id: 164,
      constituencyName: "Sarangpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sarangpur-assembly-constituency-result-2770525"
    },
    {
      id: 165,
      constituencyName: "Susner",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-susner-assembly-constituency-result-2770526"
    },
    {
      id: 166,
      constituencyName: "Agar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-agar-assembly-constituency-result-2770527"
    },
    {
      id: 167,
      constituencyName: "Shajapur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-shajapur-assembly-constituency-result-2770528"
    },
    {
      id: 168,
      constituencyName: "Shujalpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-shujalpur-assembly-constituency-result-2770529"
    },
    {
      id: 169,
      constituencyName: "Kalapipal",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kalapipal-assembly-constituency-result-2770530"
    },
    {
      id: 170,
      constituencyName: "Sonkatch",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sonkatch-assembly-constituency-result-2770531"
    },
    {
      id: 171,
      constituencyName: "Dewas",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dewas-assembly-constituency-result-2770532"
    },
    {
      id: 172,
      constituencyName: "Hatpipliya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-hatpipliya-assembly-constituency-result-2770533"
    },
    {
      id: 173,
      constituencyName: "Khategaon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khategaon-assembly-constituency-result-2770534"
    },
    {
      id: 174,
      constituencyName: "Bagli",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bagli-assembly-constituency-result-2770535"
    },
    {
      id: 175,
      constituencyName: "Mandhata",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mandhata-assembly-constituency-result-2770536"
    },
    {
      id: 176,
      constituencyName: "Harsud",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-harsud-assembly-constituency-result-2770537"
    },
    {
      id: 177,
      constituencyName: "Khandwa",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khandwa-assembly-constituency-result-2770538"
    },
    {
      id: 178,
      constituencyName: "Pandhana",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pandhana-assembly-constituency-result-2770539"
    },
    {
      id: 179,
      constituencyName: "Nepanagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-nepanagar-assembly-constituency-result-2770540"
    },
    {
      id: 180,
      constituencyName: "Burhanpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-burhanpur-assembly-constituency-result-2770541"
    },
    {
      id: 181,
      constituencyName: "Bhikangaon",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhikangaon-assembly-constituency-result-2770542"
    },
    {
      id: 182,
      constituencyName: "Badwaha",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-badwaha-assembly-constituency-result-2770543"
    },
    {
      id: 183,
      constituencyName: "Maheshwar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-maheshwar-assembly-constituency-result-2770544"
    },
    {
      id: 184,
      constituencyName: "Kasrawad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kasrawad-assembly-constituency-result-2770546"
    },
    {
      id: 185,
      constituencyName: "Khargone",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-khargone-assembly-constituency-result-2770547"
    },
    {
      id: 186,
      constituencyName: "Bhagwanpura",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-bhagwanpura-assembly-constituency-result-2770548"
    },
    {
      id: 187,
      constituencyName: "Sendhwa (S.T.)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sendhwa-st-assembly-constituency-result-2770549"
    },
    {
      id: 188,
      constituencyName: "Rajpur (S.T.)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rajpur-st-assembly-constituency-result-2770550"
    },
    {
      id: 189,
      constituencyName: "Pansemal (S.T.)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-pansemal-st-assembly-constituency-result-2770552"
    },
    {
      id: 190,
      constituencyName: "Barwani (S.T.)",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-barwani-st-assembly-constituency-result-2770553"
    },
    {
      id: 191,
      constituencyName: "Alirajpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-alirajpur-assembly-constituency-result-2770554"
    },
    {
      id: 192,
      constituencyName: "Jobat",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jobat-assembly-constituency-result-2770555"
    },
    {
      id: 193,
      constituencyName: "Jhabua",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jhabua-assembly-constituency-result-2770556"
    },
    {
      id: 194,
      constituencyName: "Thandla",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-thandla-assembly-constituency-result-2770557"
    },
    {
      id: 195,
      constituencyName: "Petlawad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-petlawad-assembly-constituency-result-2770558"
    },
    {
      id: 196,
      constituencyName: "Sardarpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sardarpur-assembly-constituency-result-2770559"
    },
    {
      id: 197,
      constituencyName: "Gandhwani",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-gandhwani-assembly-constituency-result-2770560"
    },
    {
      id: 198,
      constituencyName: "Kukshi",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-kukshi-assembly-constituency-result-2770561"
    },
    {
      id: 199,
      constituencyName: "Manawar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-manawar-assembly-constituency-result-2770562"
    },
    {
      id: 200,
      constituencyName: "Dharampuri",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dharampuri-assembly-constituency-result-2770563"
    },
    {
      id: 201,
      constituencyName: "Dhar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dhar-assembly-constituency-result-2770564"
    },
    {
      id: 202,
      constituencyName: "Badnawar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-badnawar-assembly-constituency-result-2770565"
    },
    {
      id: 203,
      constituencyName: "Depalpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-depalpur-assembly-constituency-result-2770566"
    },
    {
      id: 204,
      constituencyName: "Indore-1",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-indore-1-assembly-constituency-result-2770567"
    },
    {
      id: 205,
      constituencyName: "Indore-2",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-indore-2-assembly-constituency-result-2770568"
    },
    {
      id: 206,
      constituencyName: "Indore-3",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-indore-3-assembly-constituency-result-2770569"
    },
    {
      id: 207,
      constituencyName: "Indore-4",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-indore-4-assembly-constituency-result-2770570"
    },
    {
      id: 208,
      constituencyName: "Indore-5",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-indore-5-assembly-constituency-result-2770571"
    },
    {
      id: 209,
      constituencyName: "Dr.ambedkar Nagar -Mhow",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-dr-ambedkar-nagar-mhow-assembly-constituency-result-2770572"
    },
    {
      id: 210,
      constituencyName: "Rau",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-rau-assembly-constituency-result-2770573"
    },
    {
      id: 211,
      constituencyName: "Sanwer",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sanwer-assembly-constituency-result-2770574"
    },
    {
      id: 212,
      constituencyName: "Nagada - Khacharod",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-nagada-khacharod-assembly-constituency-result-2770575"
    },
    {
      id: 213,
      constituencyName: "Mahidpur",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mahidpur-assembly-constituency-result-2770576"
    },
    {
      id: 214,
      constituencyName: "Tarana",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-tarana-assembly-constituency-result-2770577"
    },
    {
      id: 215,
      constituencyName: "Ghatiya",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ghatiya-assembly-constituency-result-2770578"
    },
    {
      id: 216,
      constituencyName: "Ujjain North",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ujjain-north-assembly-constituency-result-2770579"
    },
    {
      id: 217,
      constituencyName: "Ujjain South",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ujjain-south-assembly-constituency-result-2770580"
    },
    {
      id: 218,
      constituencyName: "Badnagar",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-badnagar-assembly-constituency-result-2770581"
    },
    {
      id: 219,
      constituencyName: "Ratlam Rural",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ratlam-rural-assembly-constituency-result-2770582"
    },
    {
      id: 220,
      constituencyName: "Ratlam City",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-ratlam-city-assembly-constituency-result-2770583"
    },
    {
      id: 221,
      constituencyName: "Sailana",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-sailana-assembly-constituency-result-2770584"
    },
    {
      id: 222,
      constituencyName: "Jaora",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jaora-assembly-constituency-result-2770585"
    },
    {
      id: 223,
      constituencyName: "Alote",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-alote-assembly-constituency-result-2770586"
    },
    {
      id: 224,
      constituencyName: "Mandsour",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-mandsour-assembly-constituency-result-2770587"
    },
    {
      id: 225,
      constituencyName: "Malhargarh",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-malhargarh-assembly-constituency-result-2770588"
    },
    {
      id: 226,
      constituencyName: "Suwasra",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-suwasra-assembly-constituency-result-2770589"
    },
    {
      id: 227,
      constituencyName: "Garoth",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-garoth-assembly-constituency-result-2770590"
    },
    {
      id: 228,
      constituencyName: "Manasa",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-manasa-assembly-constituency-result-2770591"
    },
    {
      id: 229,
      constituencyName: "Neemuch",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-neemuch-assembly-constituency-result-2770592"
    },
    {
      id: 230,
      constituencyName: "Jawad",
      primarySection: "Madhya Pradesh (Elections)",
      secondarySection: "Madhya Pradesh (India)",
      publishedUrl:
        "https://www.deccanherald.com/elections/madhya-pradesh/madhya-pradesh-assembly-elections-2023-jawad-assembly-constituency-result-2770593"
    }
  ]
};

export const widgetUrlData = {
  Mizoram:
    "https://script.google.com/macros/s/AKfycbxTXsVFYScX6yg1cnMFLXEKOqp0r_go2cTDPPC0d9doGIoezk9VHWqLhtZxCYv9U3mw/exec",
  Rajasthan:
    "https://script.google.com/macros/s/AKfycbwmWLCMbHwHsrsaTCTD-zfFjR8v_BZlAGn-uPNPJVbECHHWbfqkH2UzdsW5DxnG0E9k/exec",
  "Madhya Pradesh":
    "https://script.google.com/macros/s/AKfycbwnOhc1qrGkpqak9IPJnDTzHGxMIo-nLyuNF1mPRUFWKCGyB4Tc8kU58oF7Angc_f3hwg/exec",
  Telangana:
    "https://script.google.com/macros/s/AKfycbyynDEaAFn6T4auFC5EP1JycbismKe5BoHwkz9CjqREW3LQTImczbxCa1wGNofFNzGwKw/exec",
  Chhattisgarh:
    "https://script.google.com/macros/s/AKfycbw4YiggkiHuxW5uXtFfcdMkU1O9_JVhbEKjDZkbwv1J9tKifFuNxkFYFQ9KENxdNqkWEA/exec"
};

export const electionSlugData = [
  {
    state: "MIZORAM",
    slug: "mizoram-assembly-elections-2023",
    showStateTabs: false
  },
  {
    state: "CHHATTISGARH",
    slug: "chhattisgarh-assembly-elections-2023",
    showStateTabs: false
  },
  {
    state: "RAJASTHAN",
    slug: "rajasthan-assembly-elections-2023",
    showStateTabs: false
  },
  {
    state: "TELANGANA",
    slug: "telangana-assembly-elections-2023",
    showStateTabs: false
  },
  {
    state: "MADHYA PRADESH",
    slug: "madhya-pradesh-assembly-elections-2023",
    showStateTabs: false
  },
  {
    state: "RAJASTHAN",
    slug: "assembly-elections-2023",
    showStateTabs: true
  }
];

export const collegeNames = [
  "GOOD WILL CHRISTIAN COLLEGE FOR WOMEN",
  "SREE VENKATESHWARA P U COLLEGE",
  "N B C DEGREE COLLEGE",
  "St JOSEPH'S INSTITUE OF MANAGEMENT",
  "St GEORGE COLLEGE OF MANAGEMENT STUDIES",
  "St JOSEPH'S UNIVERSITY",
  "BBMP PU COLLEGE FOR GIRLS HIGH SCHOOL & PU COLLEGE",
  "St CHARLES WOMEN'S PU COLLEGE",
  "St JOSEPH'S COLLEGE OF COMMERCE",
  "INDIAN ACADAMY DEGREE COLLEGE AUTONOMOUS",
  "HOLY ANGELS PUBLIC HIGH SCHOOL",
  "RASHTRIYA MILITARY SCHOOL",
  "ATMAANANDA FOUNDATION",
  "BBMP PU COLLEGE(SHIVAJINAGARA)",
  "CHRIST DEEMED UNIVERSITY(DIARY CIRCLE)",
  "FALCON PU COLLEGE(PRASHASTHA FUTURE)",
  "PARAGTHI PU COLLEGE(WHITE FIELD)",
  "SILICON DEGREE COLLEGE",
  "St ALOYSIS DEGREE COLLEGE ",
  "St ANNE'S DEGREE COLLEGE FOR WOMEN",
  "St VINCENT PALLOTTI COLLEGE",
  "TAPROOT COLLEGE(C V RAMAN NAGAR)",
  "VISION PU COLLEGE (COMMERCE) ",
  "ST.PAULS COLLEGE ",
  "SESHADRIPURAM FIRST GRADE COLLEGE Yelahanka",
  "MARIYAPPA FIRST GRADE COLLEGE",
  "AMBIKA INST OF EDUCATION SOCIETY",
  "AIRFORCE TECHNICAL COLLEGE",
  "MLA PU COLLEGE FOR WOMEN",
  "REVA INDEPENDENT P U COLLEGE",
  "NADGIR ENGLISH HIGH SCHOOL",
  "SIR M VISHWESHWARAIH VIDHYANIKETHAN",
  "B N R I P SCHOOL",
  "CHRIST DEEMED TO BE UNIVERSITY(YPR)",
  "M S PU & DEGREE COLLEGE",
  "ADHYAYANA P U COLLEGE",
  "SRI SAI P U COLLEGE",
  "NATIONAL SCHOOL OF JOURNALISM HEBBAL",
  "GOVERNEMENT FIRST GRADE COLLEGE T DASARAHALLI",
  "ASHOK POLYTECNIC",
  "BISHOP COTTON ACADAMY OF PROFESSIONAL MANAGEMENT",
  "GOVT P U COLLEGE(KODIGEHALLI)",
  "MAHARANI'S ARTS, COMMERCE & MANAGEMENT COLLEGE FOR WOMEN",
  "MAHARANI'S SCIENCE  COLLEGE FOR WOMEN",
  "MAHARANI CLUSTER UNIVERSITY",
  "MCU SMT VHD CENTRAL INSTITUTE",
  "MAHARANI LAKSHMI AMMANNI COLLEGE FOR WOMEN ",
  "REVA INDEPENDENT PU COLLEGE",
  "S J R PU COLLEGE",
  "TAPROOT COLLEGE(YELAHANKA)",
  "VIVEKANANDA COLLEGE OF LAW",
  "JYOTHI INSTITUTE OF COMMERECE & MANAGEMENT",
  "LOYALA COMPOSITE  P U COLLEGE",
  "S G P T A PRE UNIVERSITY COLLEGE",
  "SRI SIDDHAGANGA SWATANTRA PADAVI POORVA COLLEGE",
  "MAHESH P U COLLEGE",
  "BBMP PU COLLEGE",
  "SRI V S KRISHNAIYYAR COPMPOSITE PU COLLEGE",
  "V V N DEGREE COLLEGE",
  "GOVT WOMENS P U COLLEGE",
  "MKPM RV INSITUTE OF LEGAL STUDIES",
  "CHRIST(DEEMED TO BE UNIVERSITY)-DEPT OF MEDIA STUDIES",
  "BRIGHT WAY PRE UNIVERSITY COLLEGE",
  "COLUMBIA ASIA COLLEGE(BREIGADE)",
  "FULLINFAWS COLLEGE",
  "G R PU COLLEGE(KANAKAPURA)",
  "GOVT FIRST GRADE COLLEGE(JAYANAGAR)",
  "HILL SIDE PU COLLEGE",
  "INTERNATIONAL SCHOOL OF MAMENAGEMENT EXCELLENCE",
  "LOYOLA DEGREE COLLEGE ",
  "PATEL GROUP OF  INSTITUTIONS",
  "NEW VANI VILAS GOVT P U COLLEGE",
  "R V ARTS & COMMERCE COLLEGE",
  "RAINBOW PUBLIC SCHOOL",
  "SADGURU EDUCATIONAL TRUST",
  "SHARADA COLLEGE OF NURSING",
  "SILICON CITY PU COLLEGE(KK CROSS)",
  "SRI NIDHI COMPUTERS & TUTORIALS",
  "SRI VENKATESHWARA FIRST GRADE COLLEGE",
  "SUVARNA COLLEGE",
  "SUVARNA INSTITUTIONS PU COLLEGE",
  "TRANSCEND DEGREE COLLEGE",
  "VEDANTAH PU COLLEGE",
  "RNVK P U COLLEGE",
  "SUN RISE PUBLIC SCHOOL",
  "SANMATHI VIDHYA SAMSTHE",
  "BBMP PRE UNIVERSITY COLLEGE OF GIRLS",
  "NISARGA HIGH SCHOOL",
  "G V EDUCATION TRUST",
  "SRI ANUPAMA VIDYA MANDIR",
  "ARUNODAYA ENGLISH HIGH SCHOOL",
  "SRI VEENA ENGLISH PRIMARY & HIGH SCHOOL",
  "RASANA ENGLISH SCHOOL",
  "RAJJEV GANDHI PUBLIC HIGH SCHOOL",
  "SAINIK PUBLIC SCHOOL",
  "MAX MULLER PUBLIC SCHOOL .",
  "J S PRE UNIVERSITY COLLEGE",
  "THE GOLDEN VALLY PUBLIC SCHOOL",
  "ARUNODAYA PU COLLEGE",
  "BBMP FIRST GRADE DEGREE COEELGE",
  "C V GURUKULA COLLEGE",
  "JNANAGANGA INTERNATIONAL SCHOOL",
  "KLE LAW COLLEGE BENGAURU",
  "M BASAVAIAH PU COLLGE OF SCIENCE & COMMERCE",
  "MANGALORE INDEPENDENT PU COLLEGE",
  "PRAJWAL COMPOSITE PU COLLEGE",
  "RAMAN POLYTECNIC",
  "SRI ANUPAMA COLLEGE",
  "SRI SAI COLLEGE FOR WOMEN",
  "UDAYA COLLEGE",
  "VIDHYAVRDHAKA SANGHA",
  "GOVT FIRST GRADE COLLEGE VIJAYANAGARA",
  "UNNATHI GURUKULA SCHOOL",
  "G F S C-T DASARAHALLI",
  "St JOSEPH'S PRIMARY HIGH SCHOOL",
  "BMS COLLEGE OF COMMERECE & MANAGEMENT",
  "K B C PU COLLEGE",
  "SRI LAKSHMI RANGANATHA ENGLISH HIGH SCHOOL",
  "ROTARY BANGALORE VIDYALAYA",
  "OXFORD INDEPENDENT PU COLLEGE",
  "NMKRV WOMEN'S COLLEGE",
  "OXFORD COLEEGE OF ARTS",
  "KRISTU JAYANTHI COLLEGE",
  "St CLARET COLLEGE(ATONOMOUS)",
  "DAYANAND SAGAR M B A COLLGEE",
  "St ALOYSIS DEGREE COLLEGE .",
  "DAYANAND SAGAR COLLEGE OF ARTS SCIE",
  "CHRIST ACADAMY INST OF ADVANCED STU",
  "SESHADRIPURAM- Seshadripuram Branch",
  "SILICON CITY COLLEGE"
];

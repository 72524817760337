import React, { useState, useEffect } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";

import { useDispatch } from "react-redux";

import StoryCard10 from "../../molecules/story-card10";
import Pagination from "../../molecules/pagination";
import { AdContainer } from "../../ads";

import { setLoading } from "../../../components/store/actions";
import { getCollection } from "../../helper/api";
import { updateUrl } from "../../helper/utils";

import "./cartoon-page.m.css";

const CartoonPage = ({ data }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  const dispatch = useDispatch();

  const cartoonStories = data.collection?.items?.map((i) => i.story);
  const [stories, setStories] = useState(cartoonStories || []);

  const totalCount = get(data, ["collection", "total-count"], 0);
  const cardsPerPage = 24;
  const [pageNum, setPageNum] = useState(data?.pageNum);

  if (!stories.length) {
    return <h1 styleName="text-info">No Stories Found</h1>;
  }

  const loadStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    const slug = data.collection.slug;

    dispatch(setLoading(true));

    await getCollection(slug, offset, cardsPerPage)
      .then((i) => {
        setStories(i);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const pageTitle = data?.collection.metadata?.["display-name"] || data?.collection?.name;

  return (
    <div className="container" styleName="wrapper">
      <div styleName="title">
        <h1>{pageTitle}</h1>
      </div>
      {stories.length > 0 ? (
        <>
          <div styleName="collection-wrapper">
            {stories?.splice(0, 8).map((item, index) => (
              <StoryCard10 story={item} key={index} showVideoIcon={false} />
            ))}
          </div>
          {!isMobile ? (
            <div styleName="ad-wrapper" className="hide-mobile">
              <AdContainer
                AdClassName="standard-970x90"
                id="div-gpt-ad-1680156254107-0"
                desktop="DH_Desktop_HP_Billboard_Mid"
              />
            </div>
          ) : null}
          <div styleName="collection-wrapper">
            {stories?.splice(0, 16).map((item, index) => (
              <StoryCard10 story={item} key={index} showVideoIcon={false} />
            ))}
          </div>
          {!isMobile ? (
            <div styleName="ad-wrapper" className="hide-mobile">
              <AdContainer
                AdClassName="standard-970x90"
                id="div-gpt-ad-1683278304115-0"
                desktop="DH_Desktop_HP_Billboard_Bottom"
              />
            </div>
          ) : null}

          <Pagination
            loadMoreStories={loadStories}
            totalItems={totalCount}
            perPage={cardsPerPage}
            initialPage={pageNum}
          />
        </>
      ) : (
        <h1 styleName="text-info">No stories found!</h1>
      )}
    </div>
  );
};

CartoonPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { CartoonPage };

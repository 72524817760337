/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import throttle from "lodash/throttle";

import Separator from "../../atoms/separator";
import SectionBreadcrum from "../../atoms/section-breadcrum";

import Pagination from "../../molecules/pagination";
import StoryCard15 from "../../molecules/story-card15";
import TitleWithBorder from "../../molecules/title-with-border";

import { updateUrl } from "../../helper/utils";
import { getCollection } from "../../helper/api";
import { setLoading } from "../../../components/store/actions";
import { CollectionOfCollectionPage } from "../collection-of-collection-page";

import { AdContainer } from "../../ads";
import TaboolaAd from "../../ads/taboola-ad";
import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";

import { SubSectionPage } from "../sub-section-page";

import "./section-page.m.css";

const SectionPage = ({ data }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 500));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  function removeParentheses(str) {
    // Use regular expression to remove parentheses and their content
    return str.replace(/\([^)]*\)/g, "");
  }

  if (!data?.collection?.items?.length) return null;
  const { collection, section } = data;

  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const parentId = get(qtState, ["data", "section", "parent-id"], null);
  const stories = collection?.items?.filter((item) => item?.type === "story").map((i) => i.story);
  const pageTitleRaw = collection?.metadata?.["display-name"] || collection?.name || section?.name;
  const pageTitle = removeParentheses(pageTitleRaw);
  const [sectionStories, setSectionStories] = useState(stories || []);
  const [pageNum, setPageNum] = useState(data?.pageNum);
  const dispatch = useDispatch();
  const ref = useRef();
  const totalCount = get(data, ["collection", "total-count"], 0);
  const cardsPerPage = 24;

  const loadStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    const slug = data.collection?.slug;

    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getCollection(slug, offset, cardsPerPage)
      .then((i) => {
        setSectionStories(i);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  if (!stories?.length) return <CollectionOfCollectionPage data={data} />;
  if (parentId) return <SubSectionPage data={data} isSection={true} title={pageTitle} />;

  const currentPath = get(qtState, ["currentPath"], "");

  const modifiedPath = currentPath.split("?")[0];

  const desktopStories = (items) => (
    <div styleName="wrapper" className="container">
      <TaboolaScript type="category" />
      <>
        <Separator />
        {!isMobile ? (
          <div className="hide-mobile ad ad-bgc">
            <AdContainer
              AdClassName="standard-970x250"
              id="div-gpt-ad-1680156194475-0"
              desktop="DH_Desktop_HP_Billboard_Top"
            />
          </div>
        ) : null}
      </>
      <SectionBreadcrum initialPath={modifiedPath} type="section-page" />
      <TitleWithBorder name={pageTitle} />

      <div styleName="stories">
        {items.slice(0, 8).map((story, index) => {
          return <StoryCard15 story={story} key={index} />;
        })}
      </div>
      {items.slice(8, 16).length > 0 && (
        <>
          <Separator />
          {!isMobile ? (
            <div className="hide-mobile ad">
              <AdContainer id="div-gpt-ad-1680156254107-0" desktop="DH_Desktop_HP_Billboard_Mid" />
            </div>
          ) : null}
          <Separator />
        </>
      )}
      <div styleName="stories">
        {items.slice(8, 16).map((story, index) => {
          return <StoryCard15 story={story} key={index} />;
        })}
      </div>
      {items.slice(16, 24).length > 0 && (
        <>
          <Separator />
          {!isMobile ? (
            <div className="hide-mobile ad">
              <AdContainer id="div-gpt-ad-1675836921322-3" desktop="DH_Desktop_HP_Billboard_Bottom" />
            </div>
          ) : null}
        </>
      )}
      <Separator />
      <div styleName="stories">
        {items.slice(16, 24).map((story, index) => {
          return <StoryCard15 story={story} key={index} />;
        })}
      </div>
      <Separator />
      {totalCount ? (
        <Pagination
          loadMoreStories={loadStories}
          totalItems={totalCount}
          perPage={cardsPerPage}
          initialPage={pageNum}
        />
      ) : null}

      {renderTaboolaAd ? (
        <TaboolaAd
          container="taboola-below-category-thumbnails"
          placement="Below Category Thumbnails"
          mode="thumbnails-a"
        />
      ) : null}
    </div>
  );

  return (
    <div ref={ref}>
      {!isMobile ? <div className="hide-mobile">{desktopStories(sectionStories)}</div> : null}
      {isMobile ? (
        <div className="hide-desktop">
          <SubSectionPage data={data} isSection={true} title={pageTitle} />
        </div>
      ) : null}
    </div>
  );
};

SectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: object
  })
};

export { SectionPage };

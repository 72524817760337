import React from "react";
import { WithLazy } from "@quintype/components";

import HoroscopeGrid from "../../molecules/horoscope-grid";
import { AdContainer } from "../../ads";
import TitleWithBorder from "../../molecules/title-with-border";

import "./horoscope-page.m.css";

const HoroscopeListing = () => {
  return (
    <WithLazy>
      {() => (
        <div styleName="horoscope-wrapper" className="container">
          <h1 styleName="content-text title">
            <TitleWithBorder name="Horoscope" />
          </h1>
          <div styleName="content">
            <HoroscopeGrid />
          </div>
          <div styleName="ad-wrapper">
            <AdContainer
              AdClassName="standard-970x90"
              id="div-gpt-ad-1680156254107-0"
              desktop="DH_Desktop_HP_Billboard_Mid"
              mobile="DH_MWeb_HP_Top"
            />
          </div>
        </div>
      )}
    </WithLazy>
  );
};

export { HoroscopeListing };

/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

import "./newsletter-signup-card.m.css";

const NewsletterSignupCard = ({
  title,
  email,
  onEmailChange,
  signupHandler,
  success,
  successClass,
  valid,
  handleFocus,
  onClose,
  errorMessage,
  successMessage,
  termsAndPolicyChecked,
  SetTermsAndPolicy,
  invalidErrorMessage,
  showActionItems
}) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    signupHandler();
  };

  return (
    <form onSubmit={onSubmitHandler} styleName="wrapper">
      <div styleName="close-btn-wrapper" onClick={onClose}>
        <SvgIconHandler type="close" iconStyle={{ color: "#000" }} />
      </div>
      <h3>Newsletters selected - {title.join(", ")}</h3>
      {invalidErrorMessage.length > 0 ? (
        <span styleName="invalid" dangerouslySetInnerHTML={{ __html: `${invalidErrorMessage}` }}></span>
      ) : (
        ""
      )}
      {success === false &&
        invalidErrorMessage.length === 0 &&
        valid &&
        (errorMessage.length > 0 ? (
          <span styleName="invalid" dangerouslySetInnerHTML={{ __html: `${errorMessage}` }}></span>
        ) : (
          <span styleName="invalid">You have already subscribed.</span>
        ))}
      {success &&
        (successMessage.length > 0 ? (
          <span styleName="responseMessage" dangerouslySetInnerHTML={{ __html: `${successMessage}` }}></span>
        ) : (
          <span styleName="valid">Submitted Succesfully!</span>
        ))}

      {showActionItems && (
        <>
          <input
            placeholder="Email"
            type="email"
            styleName={`text-field ${successClass}`}
            value={email}
            onChange={onEmailChange}
            disabled={success}
            onFocus={handleFocus}
            ref={inputRef}
          />
          <div styleName="checkbox-wrapper">
            <input
              type="checkbox"
              id="termsAndPolicy"
              onChange={() => {
                SetTermsAndPolicy(!termsAndPolicyChecked);
              }}
              checked={termsAndPolicyChecked}
            />
            <label for="termsAndPolicy">
              {" "}
              I agree to Deccan Herald's{" "}
              <a href="/terms" target="_blank" styleName="termsAndConditions">
                Terms
              </a>{" "}
              &{" "}
              <a href="/privacy-policy" target="_blank" styleName="termsAndConditions">
                Privacy Policy
              </a>
            </label>
          </div>

          <button type="submit" disabled={success}>
            Sign up
          </button>
        </>
      )}
    </form>
  );
};

export default NewsletterSignupCard;

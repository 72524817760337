import React, { useEffect, useRef, useState } from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./radio-button-dropdown.m.css";
import throttle from "lodash/throttle";

function RadioButtonDropdown({ title, options, getSelectedOptions, option }) {
  const [checked, setChecked] = useState(option);
  const [hoverState, setHoverState] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHoverState(false);
      }
    };

    document.addEventListener("mousedown", throttle(handleClickOutside, 250));

    return () => {
      document.removeEventListener("mousedown", throttle(handleClickOutside, 250));
    };
  }, []);

  const checkChangeHandler = (e) => {
    setChecked(e.target.value);
    getSelectedOptions(e.target.value);
    setHoverState(false);
  };

  const onCheckHandler = (option) => {
    setChecked(option);
  };
  const dropdownHandler = () => {
    setHoverState(!hoverState);
  };

  return (
    <div styleName="wrapper" ref={dropdownRef}>
      <div styleName="dd" onClick={dropdownHandler}>
        <button styleName="dd-btn">
          {title}
          <SvgIconHandler type="downarrow" height="20px" width="24px" />
        </button>
        <div styleName="dropdown-section" className="hide-mobile">
          {options.map((option, idx) => (
            <div key={idx} styleName={`dropdown-option ${checked === option.value ? "changeBg" : ""}`}>
              <label>
                <input
                  type="radio"
                  value={option.value}
                  checked={checked === option.value}
                  onChange={checkChangeHandler}
                />
                {option.name}
              </label>
            </div>
          ))}
        </div>
        {hoverState && (
          <div styleName="dropdown-section-mobile" className="hide-desktop">
            {options.map((option, idx) => (
              <div
                key={idx}
                onClick={() => onCheckHandler(option.value)}
                styleName={`dropdown-option ${checked === option.value ? "changeBg" : ""}`}
              >
                <label htmlFor={`radio_${idx}`}>
                  <input
                    type="radio"
                    id={`radio_${idx}`}
                    value={option.value}
                    checked={checked === option.value}
                    onChange={checkChangeHandler}
                  />
                  {option.name}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default RadioButtonDropdown;

/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { string, shape, object } from "prop-types";
import { get } from "lodash";
import throttle from "lodash/throttle";
import { useSelector } from "react-redux";
import { LazyCollection, LazyLoadImages } from "@quintype/components";
import { getCollectionTemplate } from "../../get-collection-template";
import ElectionBrickWall from "../../molecules/election-brick-wall";
import ElectionBrickWallWithSearch from "../../molecules/election-brick-wall-with-search";
import {
  lokSabhaElectionData2024,
  lokSabhaElectionData2024StateSlugData,
  stateElections2024ConstituencyData,
  stateElections2024SlugData,
  assemblyElection2024ConstituencyData,
  assemblyElection2024SlugData,
  assemblyElection2024SlugData_JK_HR,
  assemblyElection2024SlugData_Delhi
} from "../../molecules/election-brick-wall-with-search/election-data-2024";
import { electionData, electionSlugData } from "../../molecules/election-brick-wall/election-data";

import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";
import TaboolaAd from "../../ads/taboola-ad";

import "./collection-of-collection-page.m.css";

const CollectionOfCollectionPage = ({ data }) => {
  if (!data?.collection?.items?.length) return null;
  const showBrickWall = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "show_brickWall"], false)
  );

  const { collection } = data;
  const collectionSlug = collection && collection.slug;

  const assemblyElectionsSlugs = [
    "assembly-elections-2024",
    "haryana-assembly-elections-2024",
    "jammu-and-kashmir-assembly-elections-2024",
    "maharashtra-assembly-elections-2024",
    "jharkhand-assembly-elections-2024",
    "delhi-assembly-elections-2025"
  ];
  const slugData =
    collection?.slug === "haryana-assembly-elections-2024" ||
    collection?.slug === "jammu-and-kashmir-assembly-elections-2024"
      ? assemblyElection2024SlugData_JK_HR
      : collection?.slug === "delhi-assembly-elections-2025"
      ? assemblyElection2024SlugData_Delhi
      : assemblyElection2024SlugData;

  const constituencySlugData = assemblyElectionsSlugs.includes(collectionSlug) ? slugData : stateElections2024SlugData;

  const constituencyData = assemblyElectionsSlugs.includes(collectionSlug)
    ? assemblyElection2024ConstituencyData
    : stateElections2024ConstituencyData;
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 500));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  const year = collection?.slug === "delhi-assembly-elections-2025" ? "2025" : "2024";
  return (
    <div className="container" styleName="wrapper">
      <TaboolaScript type="category" />
      <div>
        <LazyLoadImages>
          <LazyCollection
            collection={collection}
            collectionTemplates={getCollectionTemplate}
            lazyAfter={2}
            className="lazy-collection"
            collectionSlug={collectionSlug}
          />
        </LazyLoadImages>
      </div>
      {electionSlugData.map(
        (statedata) =>
          statedata?.slug === collection?.slug && (
            <ElectionBrickWall
              stateName={statedata.state}
              showStateTabs={statedata.showStateTabs}
              electionSlugData={electionSlugData}
              electionData={electionData}
              year={"2023"}
            />
          )
      )}
      {showBrickWall &&
        constituencySlugData.map(
          (statedata) =>
            statedata?.slug === collection?.slug && (
              <ElectionBrickWall
                stateName={statedata.state}
                showStateTabs={statedata.showStateTabs}
                electionSlugData={constituencySlugData}
                electionData={constituencyData}
                year={year}
              />
            )
        )}
      {collection?.slug === "lok-sabha-elections-2024" && (
        <ElectionBrickWallWithSearch
          initialStateName="Uttar Pradesh"
          electionData={lokSabhaElectionData2024}
          stateSlugData={lokSabhaElectionData2024StateSlugData}
        />
      )}

      {lokSabhaElectionData2024StateSlugData.map(
        (stateData) =>
          collection?.slug === stateData?.redirectionUrl && (
            <ElectionBrickWallWithSearch
              initialStateName={stateData?.State}
              electionData={lokSabhaElectionData2024}
              stateSlugData={lokSabhaElectionData2024StateSlugData}
            />
          )
      )}

      {renderTaboolaAd ? (
        <TaboolaAd
          container="taboola-below-category-thumbnails"
          placement="Below Category Thumbnails"
          mode="thumbnails-a"
        />
      ) : null}
    </div>
  );
};

CollectionOfCollectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { CollectionOfCollectionPage };

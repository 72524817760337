import React, { useEffect, useRef, useState } from "react";
import { object, shape } from "prop-types";
import { useDispatch } from "react-redux";
import get from "lodash/get";

import ListingPageTemplate from "../../collection-templates/listing-page-template";
import AuthorCard from "../../molecules/author-card";
import { getAuthorStories } from "../../helper/api";
import { setLoading } from "../../../components/store/actions";

import Separator from "../../atoms/separator";
import { updateUrl } from "../../helper/utils";
import StorySideBar from "../../story-templates/story-sidebar";
import MobileBottomWidgets from "../../story-templates/mobile-bottom-widgets";

import "./author.m.css";

export const AuthorPage = ({ data }) => {
  const dispatch = useDispatch();
  const authorCollection = data.authorCollection;
  const authorStories = authorCollection.items.map((i) => i.story);
  const [stories, setStories] = useState(authorStories || []);
  const [pageNum, setPageNum] = useState(data?.pageNum);
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const authorRef = useRef();

  const totalCount = get(authorCollection, ["total-count"], 0);
  const cardsPerPage = 10;
  const authorId = data.author.id;

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;

    dispatch(setLoading(true));
    authorRef.current?.scrollIntoView({
      behavior: "smooth"
    });
    await getAuthorStories(authorId, offset, cardsPerPage)
      .then((i) => {
        setStories(i);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <div className="container">
      <div styleName="wrapper">
        <div ref={authorRef} styleName="author-stories">
          <AuthorCard author={data.author} />
          <Separator />
          <ListingPageTemplate
            totalCount={totalCount}
            loadStories={getMoreStories}
            storiesList={stories}
            perPage={cardsPerPage}
            initialPage={pageNum}
          />
        </div>
        {!isMobile ? (
          <div className="side-story-section hide-mobile">
            <StorySideBar />
          </div>
        ) : null}
        {isMobile ? (
          <div className="hide-desktop">
            <MobileBottomWidgets />
          </div>
        ) : null}
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  data: shape({
    author: object,
    authorCollection: object
  })
};

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ListingPageTemplate from "../../collection-templates/listing-page-template";

import { setLoading } from "../../../components/store/actions";

import { getAdvancedSearchOfStories } from "../../../../api/utils";
import { updateUrl } from "../../helper/utils";

import "./tag-page.m.css";
import StorySideBar from "../../story-templates/story-sidebar";

const TagPage = ({ data }) => {
  if (!data) return null;
  if (!data.stories) return null;

  const ref = useRef();
  const dispatch = useDispatch();
  const cardsPerPage = 10;
  const totalCount = data?.total;
  const [tagPageStories, setStories] = useState(data?.stories);
  const [pageNum, setPageNum] = useState(data?.pageNum);

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    const requiredParams = {
      "tag-name": data?.tagName,
      limit: 10,
      offset,
      sort: "latest-published"
    };

    await getAdvancedSearchOfStories(requiredParams)
      .then((res) => {
        setStories(res.items);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const tagPageTitle = get(data, ["tags", "name"], "Tag Page");

  return (
    <div className="container" styleName="wrapper">
      <div>
        <div styleName="tag-card">
          <h1 ref={ref}>{tagPageTitle}</h1>
        </div>
        <ListingPageTemplate
          loadStories={getMoreStories}
          storiesList={tagPageStories}
          perPage={cardsPerPage}
          totalCount={totalCount}
          initialPage={pageNum}
        />
      </div>
      <div className="side-story-section hide-mobile">
        <StorySideBar />
      </div>
    </div>
  );
};

export { TagPage };

TagPage.propTypes = {
  data: PropTypes.object
};

/* eslint-disable max-len */
export const NEWS_LETTER_DATA = [
  {
    id: 0,
    tag_name: "DH_All",
    title: "All Newsletters",
    summary: "Sign up for All Newsletters"
  },
  {
    id: 1,
    tag_name: "DH_Top_News",
    title: "Top News of the Day",
    summary: "A round-up of the most important stories of the day. Stay informed about the news that matters to you.",
    duration: "Daily"
  },
  {
    id: 2,
    tag_name: "DH_Political_Theatre",
    title: "Political Theatre",
    summary: "A look at the political happenings of the week that shape the national conversation.",
    duration: "Weekly"
  },
  {
    id: 3,
    tag_name: "DH_Sportsletter",
    title: "The DH Sportsletter",
    summary:
      "The letter keeps you updated on all you need to know from the world of sports. During the IPL, it will arrive in your inboxes daily throughout the length of the tournament.",
    duration: "Weekly"
  }
  // {
  //   id: 4,
  //   tag_name: "DH_Video",
  //   title: "DH Video on Demand",
  //   summary: "Spend time with the best video storytelling from DH. Delivered on weekends.",
  //   duration: "Coming Soon"
  // },
  // {
  //   id: 5,
  //   tag_name: "DH_Radio",
  //   title: "Tune into DH Radio",
  //   summary: "A special curated look at the audio stories that deserve your attention.",
  //   duration: "Coming Soon"
  // },
  // {
  //   id: 6,
  //   tag_name: "DH_Opinion",
  //   title: "Best of DH Opinion",
  //   summary:
  //     "Explore fresh perspectives, insightful analyses, fearless editorials and sharp arguments with this letter. We address the most pressing issues every single week.",
  //   duration: "Coming Soon"
  // },
  // {
  //   id: 7,
  //   tag_name: "DH_Weekends",
  //   title: "Best of DH on Weekends",
  //   summary:
  //     "Take time to pause, reflect and feed your brain with our weekend letter. Our selection covers a range of topics and is sure to spark friendly debates. Your weekends will never be the same again.",
  //   duration: "Coming Soon"
  // }
];

import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import get from "lodash/get";

import NewsletterCard from "../../molecules/newsletter-card";
import TitleWithBorder from "../../molecules/title-with-border";
import NewsletterSignupCard from "../../molecules/newsletter-signup-card";

import { submitNewsLetter } from "../../helper/api";

import { NEWS_LETTER_DATA } from "./newletter-data";

import "./newsletter-page.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

const NewsLetterPage = () => {
  const [showSelectedLetter, setShowSelectedLetter] = useState(false);
  const [signupAllSelected, setSignupAllSelected] = useState(false);
  const [titles, setTitles] = useState([]);
  const [email, setEmail] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [success, setSuccess] = useState(null);
  const [successClass, setSuccessClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [valid, setValid] = useState(true);
  const [isSignUpClosed, setIsSignUpClosed] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [termsAndPolicyChecked, SetTermsAndPolicy] = useState(true);
  const [invalidErrorMessage, setInvalidErrorMessage] = useState("");
  const [showActionItems, setShowActionItems] = useState(true);

  const storyReducer = useSelector((state) => get(state, ["storyReducer"], []));

  const popupRef = useRef(null);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    setSuccess(null);
    setSuccessClass("");
  }, [tags]);

  useEffect(() => {
    if (!signupAllSelected) {
      const removedAllNewsCard = selectedCards.filter((item) => item !== 0);
      const removedAllNewsTag = tags.filter((item) => item !== "DH_All");
      const removedAllNewsTitle = titles.filter((item) => item !== NEWS_LETTER_DATA[0].title);
      setSelectedCards(removedAllNewsCard);
      setTags(removedAllNewsTag);
      setTitles(removedAllNewsTitle);
    }
  }, [signupAllSelected]);

  useEffect(() => {
    if (!isSignUpClosed) {
      setInvalidErrorMessage("");
    }
  }, [isSignUpClosed]);

  useEffect(() => {
    const clikedOutsidePopup = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && showActionItems === false) {
        setSelectedCards([]);
        setTitles([]);
        setTags([]);
        setSignupAllSelected(false);
        setIsSignUpClosed(true);
        setSuccess(null);
        setSuccessClass("");
        setEmail("");
        setShowActionItems(true);
      }
    };
    document.addEventListener("mousedown", clikedOutsidePopup);
    return () => document.removeEventListener("mousedown", clikedOutsidePopup);
  }, [popupRef, showActionItems]);

  const signupHandler = (idx, tag, title) => {
    if (selectedCards.includes(idx)) {
      const filteredCards = selectedCards.filter((item) => item !== idx);
      const filteredTags = tags.filter((item) => item !== tag);
      const filteredTitles = titles.filter((item) => item !== title);
      setSelectedCards(filteredCards);
      setTags(filteredTags);
      setTitles(filteredTitles);
    } else {
      setSelectedCards([...selectedCards, idx]);
      setTags([...tags, tag]);
      setTitles([...titles, title]);
    }

    setSignupAllSelected(false);
    setShowSelectedLetter(!showSelectedLetter);
    setIsSignUpClosed(false);
  };
  const signupAllHandler = (e) => {
    setShowSelectedLetter(0);
    setSelectedCards([0]);
    setSignupAllSelected(!signupAllSelected);
    setTags(["DH_All"]);
    setTitles([NEWS_LETTER_DATA[0].title]);
    setIsSignUpClosed(false);
  };

  const onCloseHandler = () => {
    if (invalidErrorMessage === "") {
      setSelectedCards([]);
      setTitles([]);
      setTags([]);
      setSignupAllSelected(false);
    }
    setIsSignUpClosed(true);
    setSuccess(null);
    setSuccessClass("");
    setEmail("");
    setShowActionItems(true);
  };

  const handleFocus = () => setValid(true);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(email);
  };
  const updateEmailField = (e) => setEmail(e.target.value);
  const submitEmail = () => {
    if (!email) {
      setInvalidErrorMessage("Email field is empty");
      if (!termsAndPolicyChecked) {
        setInvalidErrorMessage("Email field is empty <br> Please agree to the Terms and Privacy Policy");
      }
      return;
    } else if (!validateEmail(email)) {
      setValid(false);
      setInvalidErrorMessage("Invalid Email");
      if (!termsAndPolicyChecked) {
        setInvalidErrorMessage("Invalid Email <br> Please agree to the Terms and Privacy Policy");
      }
      return;
    } else if (!termsAndPolicyChecked) {
      setInvalidErrorMessage("Please agree to the Terms and Privacy Policy");
      return;
    } else {
      setInvalidErrorMessage("");
    }

    const body = {
      email_address: email,
      tags: tags,
      status: "pending"
    };

    submitNewsLetter(body)
      .then((res) => {
        setEmail("");
        setSuccess(true);
        setSuccessMessage(res);
        setShowActionItems(false);
      })
      .catch((res) => {
        setEmail("");
        setSuccessClass("failure");
        setSuccess(false);
        setErrorMessage(res?.response?.data || res?.message);
      })
      .finally(() => {
        setSuccessClass("success");
      });
  };

  const isMobileBannerVisible = storyReducer.isStickyBannerVisible;

  return (
    <>
      <div styleName="container" className="container">
        <TitleWithBorder name="Newsletters" />
        <div styleName="wrapper">
          <div styleName={`signup-all ${signupAllSelected ? "selected-card" : ""} `}>
            <div styleName="signup-all-content">
              <div>
                <h2>Stay ahead, stay engaged!</h2>
                <p>
                  Skip the headlines and get the heart of the matter in news, politics and sports with our 'Top News',
                  'Political Theatre' and 'The DH Sportsletter' newsletters!
                </p>
              </div>

              <button onClick={(e) => signupAllHandler(e)}>Sign up for All</button>
            </div>
            <div styleName="img-container" className="hide-mobile">
              <SvgIconHandler type="newsletterimg" width="223px" height="138px" />
            </div>
          </div>
          {NEWS_LETTER_DATA.slice(1).map((item, index) => (
            <NewsletterCard item={item} key={index} onSignup={signupHandler} isSelected={selectedCards} />
          ))}
        </div>
      </div>
      {isMobile
        ? (selectedCards.length > 0 || signupAllSelected) &&
          !isSignUpClosed && (
            <div
              styleName={`${!isMobileBannerVisible ? "newsletter-modal-without-ad" : "newsletter-modal"}`}
              ref={popupRef}
            >
              <NewsletterSignupCard
                title={titles}
                email={email}
                onEmailChange={updateEmailField}
                signupHandler={submitEmail}
                success={success}
                successClass={successClass}
                valid={valid}
                handleFocus={handleFocus}
                onClose={onCloseHandler}
                errorMessage={errorMessage}
                successMessage={successMessage}
                SetTermsAndPolicy={SetTermsAndPolicy}
                invalidErrorMessage={invalidErrorMessage}
                termsAndPolicyChecked={termsAndPolicyChecked}
                showActionItems={showActionItems}
              />
            </div>
          )
        : (selectedCards.length > 0 || signupAllSelected) &&
          !isSignUpClosed && (
            <div styleName="newsletter-modal" ref={popupRef}>
              <NewsletterSignupCard
                title={titles}
                email={email}
                onEmailChange={updateEmailField}
                signupHandler={submitEmail}
                success={success}
                successClass={successClass}
                valid={valid}
                handleFocus={handleFocus}
                onClose={onCloseHandler}
                errorMessage={errorMessage}
                successMessage={successMessage}
                SetTermsAndPolicy={SetTermsAndPolicy}
                invalidErrorMessage={invalidErrorMessage}
                termsAndPolicyChecked={termsAndPolicyChecked}
                showActionItems={showActionItems}
              />
            </div>
          )}
    </>
  );
};

export { NewsLetterPage };

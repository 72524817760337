import React, { useState, useEffect } from "react";
import { string, shape, object } from "prop-types";
import Separator from "../../atoms/separator";
import StoryCard15 from "../../molecules/story-card15";
import TitleWithBorder from "../../molecules/title-with-border";
import { AdContainer } from "../../ads";
import { SubSectionPage } from "../sub-section-page";

import "./brandspot-page.m.css";

const BrandSpotPage = ({ data }) => {
  if (!data?.collection?.items?.length) return null;
  const { collection } = data;

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const stories = collection?.items?.filter((item) => item?.type === "story").slice(0, 24);

  const desktopStories = (items) => (
    <div styleName="wrapper" className="container">
      <TitleWithBorder name="BrandSpot" />
      <div styleName="stories">
        {items.slice(0, 8).map((item, index) => {
          return <StoryCard15 story={item.story} key={index} />;
        })}
      </div>
      <Separator />
      {!isMobile ? (
        <div className="hide-mobile ad">
          <AdContainer id="div-gpt-ad-1680156254107-0" desktop="DH_Desktop_HP_Billboard_Mid" />
        </div>
      ) : null}
      <Separator />
      <div styleName="stories">
        {items.slice(8, 16).map((item, index) => {
          return <StoryCard15 story={item.story} key={index} />;
        })}
      </div>
      <Separator />
      {!isMobile ? (
        <div className="hide-mobile ad">
          <AdContainer id="div-gpt-ad-1680156254107-0" desktop="DH_Desktop_HP_Billboard_Mid" />
        </div>
      ) : null}
      <div styleName="stories">
        {items.slice(16, 24).map((item, index) => {
          return <StoryCard15 story={item.story} key={index} />;
        })}
      </div>
      <Separator />
      {!isMobile ? (
        <div className="hide-mobile ad">
          <AdContainer id="div-gpt-ad-1675836921322-3" desktop="DH_Desktop_HP_Billboard_Bottom" />
        </div>
      ) : null}
    </div>
  );

  return (
    <div>
      <div className="hide-mobile">{desktopStories(stories)}</div>
      <div className="hide-desktop">
        <SubSectionPage
          data={data}
          isSection={true}
          title={data?.section?.["display-name"] || data?.collection?.metadata?.["display-name"]}
        />
      </div>
    </div>
  );
};

BrandSpotPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { BrandSpotPage };

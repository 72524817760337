export const TERMS_CONDITIONS = [
  {
    termTitle: "Eligibility:",
    subTerms: [
      "Participants must be enrolled to our knowledge-Kit package through their institution.",
      "Each participant must register individually and provide valid identification."
    ]
  },
  {
    termTitle: "Sign-In:",
    subTerms: ["Sign-in to be done by providing valid identification details."]
  },
  {
    termTitle: "Participation:",
    subTerms: [
      "Participants must have a stable internet connection and access to a computer or mobile device.",
      "The quiz will need to be attended through the link provided on the specified date and time.",
      "Details will be shared via message, email and on the official website to the enrolled institutions."
    ]
  },
  {
    termTitle: "Knowledge-Quest Format:",
    subTerms: ["The ‘Knowledge-Quest’ will consist of questions covering various topics."]
  },
  {
    termTitle: "Conduct:",
    subTerms: [
      "Participants must adhere to the rules and instructions provided.",
      "Any form of cheating, including using unauthorized resources or assistance, is strictly prohibited.",
      "The organizer reserves the right to disqualify any participant found violating the rules."
    ]
  },
  {
    termTitle: "Scoring and Results:",
    subTerms: [
      "Scores will be calculated based on the number of correct answers and the time taken to complete the quiz.",
      "In the event of a tie, the participant who completed the quiz in the shortest time will be declared the winner.",
      "Winners will be announced on the official website or via email to the institutions."
    ]
  },
  {
    termTitle: "Prizes/Certification:",
    subTerms: [
      "Prizes/Certification will be awarded to the top-performing participants.",
      "The organizer will not be responsible for any defects/inadequacy in the products/services redeemed in lieu of the prizes/coupons/gift vouchers and the same would have to be addressed to the sponsor/service provider/manufacturer directly. The organizer shall not be liable to replace/exchange the Prize/products/services at any stage.",
      "Decision of the organizers will be final and binding on all the Participants and no correspondence will be entertained in this regard.",
      "Prizes cannot be exchanged for their value in cash or for other prizes. Each prize shall have a validity period and the same can be redeemed with the respective sponsors within such validity period as mentioned on the Prize. Further, any charge over and above the stated Prize shall have to be borne by the Participant themselves including without limitation travelling, prize collection/delivery charges etc.",
      "In case any Participant refuses to accept the Prize decided for him/her, his/her participation to the Quiz will be declared null and void. No correspondence shall be entertained in this regard.",
      "The Prize(s) are non-transferable."
    ]
  },
  {
    termTitle: "Privacy:",
    subTerms: [
      "Participants’ personal information will be collected for the purpose of registration and administration of the quiz.",
      "The organizer will not share participants’ personal information with third parties without consent, except as required by law."
    ]
  },
  {
    termTitle: "Liability:",
    subTerms: [
      "The organizer is not responsible for any technical issues, including internet failures or disruptions, that may affect participants&#39; ability to complete the quiz.",
      "Participants assume all risks related to their participation in the quiz."
    ]
  },
  {
    termTitle: "Changes and Cancellation:",
    subTerms: [
      "The organizer reserves the right to modify or cancel the quiz at any time without prior notice.",
      "Any changes will be communicated to participants via email and/or official website to the institutions enrolled in this program.",
      "The organizer reserves the right to cancel or amend all or any part of the Quiz and/or the terms &amp; conditions/technical parameters without prior notice in the event of circumstances arising beyond its control that makes it necessary to do so. However, any changes to the terms &amp; conditions/technical parameters or cancellation of the Quiz, will be updated/posted on ________(website). It would be the exclusive responsibility of the Participants alone to keep themselves informed as to any changes in the terms &amp; conditions/technical parameters/evaluation criteria stated for this Quiz."
    ]
  },
  {
    termTitle: "General, Governing law and Jurisdiction:",
    subTerms: [
      "The Quiz is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The organizer expressly disclaims all warranties of any kind, whether express or implied. The organizer does not make any warranty that the Quiz and or emanating results will meet your expectations.",
      "The organizer will in no event be liable for any damages whether direct, indirect, special, incidental, or consequential arising out of the Quiz or use of the Prize(s) given through the Quiz, in the event any disclaimer is held invalid, the liability of the organizer under this Quiz shall not exceed the value of lowest single Prize.",
      "The Participants hereby agree and undertake not to hold the organizer and/or or any of their group entities or affiliates, their respective directors, officers, employees, agents, vendors, responsible for or liable for, any actions, claims, demands, losses, damages, costs, charges and expenses that the Participant may/might have suffered, sustained or incurred, or claims to suffer, sustain or incur, by way of and /or on account of participation in the Quiz or related to the Prize in any manner whatsoever.",
      "This Quiz shall be governed by and construed in accordance with the laws of India. Any disputes, differences and/or any other matters in relation and arising out of this Quiz and/or pertaining to the rules and regulations of this Quiz shall be referred to a sole arbitrator appointed by the organizer in accordance with the provisions of the Arbitration &amp; Conciliation Act, 1996 and any subsequent amendment thereof. The arbitration proceedings shall be held in English and the seat of arbitration shall be at Bangalore. The arbitral award shall be final and binding.",
      "All disputes shall be subject to exclusive jurisdiction of Bangalore courts only."
    ]
  },
  {
    termTitle: "Acceptance:",
    subTerms: ["By registering for the quiz, participants agree to abide by these terms and conditions."]
  }
];

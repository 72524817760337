import React from "react";
import { object, array, number, string } from "prop-types";

import { Link } from "@quintype/components";
import CardImage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";

import "./instagram-card.m.css";

const InstagramCard = ({ story, aspectRatio, imageType, headerType, headerLevel }) => {
  return (
    <div className="story-card story-card-8" styleName="instagram-card">
      <Link href={`/${story.slug}`}>
        <div styleName="card-wrapper">
          <CardImage
            showVideoIcon
            story={story}
            aspectRatio={aspectRatio}
            imageType={imageType}
            renderedWidth={720}
            widths={[700]}
          />
          <div styleName="content">
            <Headline
              text={story.headline}
              headerType={headerType}
              headerLevel={headerLevel}
              story={story}
              lineClampClass="line-clamp line-clamp-2"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

InstagramCard.defaultProps = {
  aspectRatio: [1, 1],
  imageType: "image1x1",
  headerType: 5,
  headerLevel: 2
};

InstagramCard.propTypes = {
  story: object.isRequired,
  aspectRatio: array,
  imageType: string,
  headerType: number,
  headerLevel: number
};

export default InstagramCard;

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { format, startOfDay, endOfDay } from "date-fns";
import DatePicker from "react-datepicker";

import { getAdvancedSearchOfStories } from "../../../../api/utils";
import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";

import Separator from "../../atoms/separator";
import CheckBoxDropdown from "../../atoms/checkbox-dropdown";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import RadioButtonDropdown from "../../atoms/radio-button-dropdown";

import SearchBar from "../../molecules/search-bar";

import { updateUrl } from "../../helper/utils";
import { setLoading } from "../../../components/store/actions";
import ListingPageTemplate from "../../collection-templates/listing-page-template";

import "./search.m.css";
import StorySideBar from "../../story-templates/story-sidebar";

const templates = [
  { id: "text", "display-name": "Story" },
  { id: "video", "display-name": "Video" },
  { id: "photo", "display-name": "Photo" },
  { id: "podcast", "display-name": "Podcast" }
];

const SearchPage = ({ data }) => {
  const dispatch = useDispatch();
  const cardsPerPage = 10;
  const searchParam = data?.query?.replace(/\?access_token=[^&]+/, "")?.replace(/\?.*/, "");
  const totalCount = data?.total;

  const defaultStories = searchParam ? data?.stories?.map((story) => story.story) : [];

  const [pageNum, setPageNum] = useState(data?.pageNum);
  const [stories, setStories] = useState(defaultStories || []);
  const [searchedText, setSearchedText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOption, setSortOption] = useState("score");
  const [templateOption, setTemplateOption] = useState("");
  const [filterToggle, setToggleFilter] = useState(false);
  const [sectionNames, setSectionNames] = useState("");
  const [total, setTotal] = useState(totalCount);
  const [showItems, setShowItems] = useState(false);
  const [loadNoStoryFound, setLoadNotStoryFound] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const dateParam = { startDate: Number(startOfDay(startDate)), endDate: Number(endOfDay(endDate)) };
  const searchRef = useRef();
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (searchParam) {
      setIsLoading(false);
    }
    if (showItems) {
      setInitialPage(1);
      let queryParams = {
        ...(searchParam && { q: searchParam }),
        ...(sectionNames && { type: sectionNames }),
        ...(dateParam.startDate && { "published-after": dateParam.startDate }),
        ...(dateParam.endDate && { "published-before": dateParam.endDate })
      };
      getAdvancedSearchOfStories({
        sort: sortOption,
        ...queryParams
      })
        .then((response) => {
          setIsLoading(false);
          setStories(response.items);
          setTotal(response.total);
          setLoadNotStoryFound(false);
        })
        .catch((err) => {
          console.log("err:>:>", err);
          setLoadNotStoryFound(true);
        });
    }
  }, [
    dateParam.startDate,
    dateParam.endDate,
    sortOption,
    sectionNames,
    searchParam,
    filterToggle,
    templateOption,
    showItems
  ]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (filterToggle) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [filterToggle]);

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    setInitialPage(page);
    let requiredParams = {
      sort: sortOption,
      ...(searchParam && { q: searchParam }),
      ...(sectionNames && { type: sectionNames }),
      ...(dateParam.startDate && { "published-after": dateParam.startDate }),
      ...(dateParam.endDate && { "published-before": dateParam.endDate }),
      limit: cardsPerPage,
      offset
    };

    dispatch(setLoading(true));
    searchRef.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getAdvancedSearchOfStories(requiredParams)
      .then((res) => {
        setStories(res.items);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const filterToggleHandler = (toggleValue) => {
    setToggleFilter(toggleValue);
    setShowItems(false);
    setIsLoading(false);
  };

  const sortOptions = [
    { name: "Relevance", value: "score" },
    { name: "Recently published", value: "latest-published" }
  ];

  const getSortOptionValue = (sortOption) => {
    setSortOption(sortOption);

    setShowItems(true);
  };

  const getSearchedText = (text) => {
    setSearchedText(text);
    setShowItems(true);
  };

  const getSectionNames = (names) => {
    const updatedSectionNames = names.join(", ");
    setSectionNames(updatedSectionNames);
    setShowItems(true);
  };

  const DateInput = forwardRef(({ onClick, date }, ref) => {
    return (
      <div onClick={onClick} ref={ref}>
        {date == null ? "DD/MM/YYYY" : format(date, "dd/MM/yyyy")}
      </div>
    );
  });

  return (
    <>
      <div className="container" styleName="wrapper">
        <TaboolaScript type="category" />
        <div>
          <SearchBar
            filterToggleHandler={filterToggleHandler}
            getSearchedText={getSearchedText}
            defaultValue={searchParam}
          />
          <div styleName="filter-section">
            <div styleName="filter-wrapper">
              <CheckBoxDropdown title="Story type" options={templates} getSelectedOptions={getSectionNames} />
              <RadioButtonDropdown
                title="Sort"
                options={sortOptions}
                getSelectedOptions={getSortOptionValue}
                option={sortOption}
              />
            </div>
            <div styleName="range-date">
              <DatePicker
                selected={startDate}
                maxDate={endDate ? new Date(endDate) : new Date()}
                onChange={(date) => {
                  setStartDate(date);
                  setShowItems(true);
                }}
                customInput={<DateInput date={startDate} />}
              />
              <span styleName="hypen"> - </span>
              <DatePicker
                selected={endDate}
                minDate={startDate && new Date(startDate)}
                maxDate={new Date()}
                onChange={(date) => {
                  setEndDate(date);
                  setShowItems(true);
                }}
                customInput={<DateInput date={endDate} />}
              />
              <div styleName="calender-icon">
                <SvgIconHandler type="calendericon" height="24px" width="24px" />
              </div>
            </div>
          </div>
          <Separator />
          <div ref={searchRef} styleName="search-card"></div>
          {isLoading ? null : (
            <ListingPageTemplate
              loadStories={getMoreStories}
              storiesList={stories}
              perPage={cardsPerPage}
              totalCount={total}
              initialPage={initialPage}
            />
          )}
          {loadNoStoryFound && (
            <h1 styleName="text-info">
              {searchedText ||
              sectionNames ||
              templateOption ||
              dateParam.startDate ||
              dateParam.endDate ||
              (searchParam && !stories.length)
                ? "No results available"
                : ""}
            </h1>
          )}
        </div>
        {!isMobile ? (
          <div className="side-story-section hide-mobile">
            <StorySideBar />
          </div>
        ) : null}
      </div>
    </>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object
};

export { SearchPage };

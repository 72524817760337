import React, { useEffect, useState } from "react";
import throttle from "lodash/throttle";
import { Link } from "@quintype/components";
import { AdContainer } from "../.../../ads";

import TaboolaAd from "../ads/taboola-ad";
import TaboolaScript from "../ads/taboola-ad/load-taboola-ad";
import Separator from "../atoms/separator";
import SevenStories from "../collection-templates/seven-stories";

import "./not-found.m.css";
import { getCollection } from "../helper/api";

const NotFoundPage = () => {
  const [coll, setColl] = useState([]);
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 500));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    renderTaboolaAd && getData();
  }, [renderTaboolaAd]);

  const getData = async () => {
    try {
      const res = await getCollection("top-news-today", 0, 10, "");
      if (res?.length > 0) {
        setColl({ name: "Top Stories", items: res });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <TaboolaScript type="category" />
      <Separator />
      <div styleName="ad-wrapper ad-bgc">
        <AdContainer id="div-gpt-ad-1680156194475-0" desktop="DH_Desktop_HP_Billboard_Top" mobile="DH_MWeb_HP_Top" />
      </div>
      <Separator />
      <div styleName="page-not-container">
        <h1>Page not found :(</h1>
        <p>Sorry, the page you are looking for is no longer available</p>

        <Link href="/" styleName="homepage-btn">
          <button>Go back to Home Page</button>
        </Link>
        <div styleName="extra-info">
          <p>OR</p>
          <p>Check out the stories below</p>
        </div>
      </div>
      <Separator />
      <div styleName="stories">
        <SevenStories collection={coll} page="not-found" />
      </div>
      {renderTaboolaAd ? (
        <TaboolaAd
          container="taboola-below-category-thumbnails"
          placement="Below Category Thumbnails"
          mode="thumbnails-a"
        />
      ) : null}
    </div>
  );
};

export { NotFoundPage };

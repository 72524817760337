import React from "react";
import { Link } from "@quintype/components";

import AuthorDefaultLogo from "../../atoms/author-default-img";

import "./author-listing-card.m.css";

const AuthorListingCard = ({ author }) => {
  const authorImg = author["avatar-url"] || author["avatar-s3-key"];

  return (
    <Link href={`/author/${author?.slug}`} styleName="wrapper">
      <div styleName="author-image">
        {authorImg ? <img src={authorImg} alt={author?.name} /> : <AuthorDefaultLogo width={240} height={240} />}
      </div>
      <h2 styleName="author-name">{author?.name}</h2>
    </Link>
  );
};

export default AuthorListingCard;

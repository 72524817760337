/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import throttle from "lodash/throttle";

import ListingStoryCard from "../../molecules/listing-story-card";
import Pagination from "../../molecules/pagination";

import { AdContainer } from "../../ads";
import TaboolaAd from "../../ads/taboola-ad";
import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";
import MobileBottomWidgets from "../../story-templates/mobile-bottom-widgets";

import "./listing-page-template.m.css";

const ListingPageTemplate = ({ storiesList, loadStories, perPage, totalCount, initialPage, sectionName }) => {
  if (storiesList && !storiesList.length) {
    return <h1 styleName="text-info">No results available</h1>;
  }
  const [toggleSharing, setToggleSharing] = useState(false);
  const ToggleHandler = (key) => {
    setToggleSharing(toggleSharing === key ? undefined : key);
  };

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 300));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  return (
    <div>
      <TaboolaScript type="category" />
      {storiesList &&
        storiesList
          .slice(0, 3)
          .map((item, index) => (
            <ListingStoryCard
              story={item}
              key={index}
              sectionName={sectionName}
              toggleSharing={toggleSharing}
              ToggleHandler={ToggleHandler}
            />
          ))}
      {isMobile ? (
        <div styleName="ad-wrapper" className="hide-desktop">
          <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156938221-0" mobile="DH_MWeb_HP_Top" />
        </div>
      ) : null}
      {storiesList &&
        storiesList
          .slice(3, 7)
          .map((item, index) => (
            <ListingStoryCard
              story={item}
              key={index}
              sectionName={sectionName}
              toggleSharing={toggleSharing}
              ToggleHandler={ToggleHandler}
            />
          ))}
      {isMobile ? (
        <div styleName="ad-wrapper" className="hide-desktop">
          <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156970809-0" mobile="DH_MWeb_HP_Mid" />
        </div>
      ) : null}
      {storiesList &&
        storiesList
          .slice(7, 10)
          .map((item, index) => (
            <ListingStoryCard
              story={item}
              key={index}
              sectionName={sectionName}
              toggleSharing={toggleSharing}
              ToggleHandler={ToggleHandler}
            />
          ))}
      {isMobile ? (
        <div styleName="ad-wrapper" className="hide-desktop">
          <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1680156998073-0" mobile="DH_MWeb_HP_Bottom" />
        </div>
      ) : null}
      {totalCount ? (
        <Pagination loadMoreStories={loadStories} totalItems={totalCount} perPage={perPage} initialPage={initialPage} />
      ) : null}
      {isMobile ? (
        <div className="hide-desktop">
          <MobileBottomWidgets />
        </div>
      ) : null}
      {renderTaboolaAd ? (
        <TaboolaAd
          container="taboola-below-category-thumbnails"
          placement="Below Category Thumbnails"
          mode="thumbnails-a"
        />
      ) : null}
    </div>
  );
};

export default ListingPageTemplate;

import React, { useEffect, useRef, useState } from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./checkbox-dropdown.m.css";
import throttle from "lodash/throttle";

function CheckBoxDropdown({ title, options, getSelectedOptions }) {
  const [checked, setChecked] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", throttle(handleClickOutside, 250));
    return () => {
      document.removeEventListener("mousedown", throttle(handleClickOutside, 250));
    };
  }, []);

  const toggleCheckbox = (option_id) => {
    const isChecked = checked.includes(option_id);
    let updatedList = [...checked];

    if (isChecked) {
      updatedList = updatedList.filter((item) => item !== option_id);
    } else {
      updatedList.push(option_id);
    }

    setChecked(updatedList);
    getSelectedOptions(updatedList);
  };

  const changeBg = (item) => {
    return checked.includes(item) ? "changeBg" : "";
  };
  const dropdownHandler = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div styleName="wrapper" ref={dropdownRef}>
      <div styleName="dd">
        <button styleName={`dd-btn `} onClick={dropdownHandler}>
          {title}
          <SvgIconHandler type="downarrow" height="20px" width="24px" />
        </button>

        <div styleName="dropdown-section" className="hide-mobile">
          {options.map((option) => {
            const option_id = option.id.toString();
            return (
              <div
                key={option_id}
                styleName={`dropdown-option ${changeBg(option_id)}`}
                onClick={() => toggleCheckbox(option_id)}
              >
                <input type="checkbox" value={option_id} checked={checked.includes(option_id)} readOnly />
                <span>{option["display-name"]}</span>
              </div>
            );
          })}
        </div>

        {showOptions && (
          <div styleName="dropdown-section" className="hide-desktop">
            {options.map((option) => {
              const option_id = option.id.toString();
              return (
                <div
                  key={option_id}
                  styleName={`dropdown-option ${changeBg(option_id)}`}
                  onClick={() => toggleCheckbox(option_id)}
                >
                  <input type="checkbox" value={option_id} checked={checked.includes(option_id)} readOnly />
                  <span>{option["display-name"]}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckBoxDropdown;

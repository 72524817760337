import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { setUserDetails } from "../../store/actions";
import { putUserDetails } from "../../helper/utils";
import { collegeNames } from "./college-names";

import "./student-contest.m.css";
import Modal from "../../atoms/modal";
import { TERMS_CONDITIONS } from "../../helper/terms-condition";
import { Link } from "@quintype/components";
import { pushEventToDataLayer } from "../../helper/datalayer-utils";

const collegesSet = new Set(collegeNames);

function StudentContestPage() {
  const [loadPuzzle, setLoadPuzzle] = useState(false);
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));
  const [currentScreen, setCurrentScreen] = useState("");
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const previousScreen = useRef(null);

  const setCurrentScreenWithRefUpdate = (newScreen) => {
    previousScreen.current = currentScreen;
    setCurrentScreen(newScreen);
  };

  useEffect(() => {
    if (window) {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        if (userdetails?.userData?.id) {
          if (userdetails?.metadata?.college && userdetails?.metadata?.displayName) {
            setCurrentScreenWithRefUpdate("");
            setLoadPuzzle(true);
          } else {
            setCurrentScreenWithRefUpdate("form");
          }
        }
      } else {
        setCurrentScreenWithRefUpdate("start");
      }
    }
  }, [userdetails?.userData?.id]);

  useEffect(() => {
    if (document && loadPuzzle) {
      const embedDiv = document.getElementsByClassName("pm-embed-div")[0];
      if (embedDiv) {
        window.embedGame();
      }
    }
  }, [loadPuzzle]);

  const clickHandler = (type) => {
    switch (type) {
      case "start-continue":
        setCurrentScreenWithRefUpdate("info");
        break;
      case "info-no":
        if (previousScreen.current === "start") {
          setCurrentScreenWithRefUpdate("start");
        } else {
          setCurrentScreenWithRefUpdate("form");
        }
        break;
      case "info-yes":
        setCurrentScreenWithRefUpdate("");
        setLoadPuzzle(true);
        break;
      case "form-cancel":
        setCurrentScreenWithRefUpdate("info");
        break;
      default:
        break;
    }
  };

  function StartScreen() {
    const signinHandler = () => {
      if (window?.dataLayer) pushEventToDataLayer("SSO_Page_Open");

      window.location.href = `https://web.tpml.in/auth?redirectUrl=${window.location.href}`;
    };
    return (
      <div styleName="button-screen-wrapper">
        <span styleName="cross-word-button-screen-title">Sign in or create an account to participate</span>
        <button styleName="cross-word-signin" onClick={signinHandler}>
          Sign In
        </button>
        <button styleName="cross-word-continue" onClick={() => clickHandler("start-continue")}>
          Continue Without Sign In
        </button>
      </div>
    );
  }

  function InfoScreen() {
    return (
      <>
        <span styleName="cross-word-info-screen-title">
          Proceeding without providing your details will make you ineligible for winning the contest. Are you sure you
          want to proceed without providing details?
        </span>
        <div styleName="info-buttons-wrapper">
          <button styleName="cross-word-info-no" onClick={() => clickHandler("info-no")}>
            No
          </button>
          <button styleName="cross-word-info-yes" onClick={() => clickHandler("info-yes")}>
            Yes
          </button>
        </div>
      </>
    );
  }

  function FormScreen({ openTermsHandler }) {
    const [filteredRecords, setFilteredRecords] = useState([]);
    const modalRef = useRef(null);
    const [collegeName, setCollegeName] = useState("");
    const [studentName, setStudentName] = useState(userdetails?.metadata?.displayName || "");
    const [disable, setDisable] = useState(true);
    const [openModal, setModal] = useState(false);
    const dispatch = useDispatch();

    const handleCollegeChange = (event) => {
      const value = event.target.value;
      setCollegeName(value);

      const filteredrecords = collegeNames.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
      setModal(true);
      setFilteredRecords(filteredrecords);
    };

    useEffect(() => {
      const clikedOutsideModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setModal(false);
        }
      };
      document.addEventListener("mousedown", clikedOutsideModal);
      return () => document.removeEventListener("mousedown", clikedOutsideModal);
    }, [modalRef]);

    useEffect(() => {
      if (collegeName.trim() === "" || studentName.trim() === "") {
        setDisable(true);
      } else if (!collegesSet.has(collegeName.trim())) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }, [collegeName, studentName]);

    const updateUserdetails = async () => {
      const data = {
        ...userdetails?.metadata,
        college: collegeName,
        displayName: studentName
      };
      setDisable(true);
      const details = await putUserDetails(data);
      let localUserData;
      const user = window?.localStorage?.getItem("user");
      if (user !== "undefined") {
        if (typeof user === "string" && user.trim() !== "") {
          localUserData = JSON.parse(user);
        }
      }

      window.localStorage.setItem(
        "user",
        JSON.stringify({ ...localUserData, metadata: { ...localUserData?.metadata, ...details } })
      );
      dispatch(setUserDetails({ ...userdetails, metadata: { ...userdetails?.metadata, ...details } }));
      setDisable(false);
      setCurrentScreenWithRefUpdate("");
      setLoadPuzzle(true);
    };

    return (
      <div styleName="form-wrapper">
        <span styleName="cross-word-form-title">Update Profile</span>
        <span styleName="cross-word-form-name-label">Name</span>
        <input
          type="text"
          styleName="cross-word-form-name-field"
          value={studentName}
          onChange={(e) => {
            setStudentName(e.target.value);
          }}
        ></input>
        <span styleName="cross-word-form-college-label">College Name</span>
        <div styleName="search-wrapper">
          <input
            type="text"
            styleName="cross-word-form-college-field"
            placeholder="Search your college"
            value={collegeName}
            onChange={handleCollegeChange}
          ></input>
          <div styleName="dropdown" ref={modalRef}>
            {collegeName.length > 0 &&
              openModal &&
              (filteredRecords.length > 0 ? (
                filteredRecords.slice(0, 5).map((item, index) => (
                  <div
                    key={item + index}
                    styleName="dropdown-item"
                    onClick={() => {
                      setCollegeName(item);
                      setModal(false);
                    }}
                  >
                    {item}
                  </div>
                ))
              ) : (
                <div
                  styleName="dropdown-item-not-found"
                  // onClick={() => {
                  //   setModal(false);
                  // }}
                >
                  No college found
                </div>
              ))}
          </div>
        </div>
        <div styleName="term-condition-wrapper">
          <input type="checkbox" checked={true} disabled />
          <span styleName="condition-text">
            I agree to <span onClick={openTermsHandler}>Terms & Conditions</span> of Knowledge Conquest
          </span>
        </div>

        <div styleName="info-buttons-wrapper">
          <button
            styleName="cross-word-form-cancel"
            onClick={() => {
              clickHandler("form-cancel");
            }}
          >
            Cancel
          </button>
          <button styleName="cross-word-form-save" onClick={updateUserdetails} disabled={disable}>
            Save
          </button>
        </div>
      </div>
    );
  }

  const [termConditionModal, setTermCondtionModal] = useState(false);

  const termConditionModalOpenHandler = () => setTermCondtionModal(true);
  const termConditionModalCloseHandler = () => setTermCondtionModal(false);

  useEffect(() => {
    if (!loadPuzzle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [loadPuzzle]);

  const handleUpdateClick = () => {
    if (window) {
      window.location.reload();
    }
  };

  return (
    <div styleName="student-contest-wrapper">
      <div styleName="title-wrapper">
        <div styleName="puzzle-logo">
          <img src="https://images.assettype.com/deccanherald/2024-07/b45398df-ff48-4cb0-9219-f41709af2fc3/knowledge_logo.svg"></img>
        </div>
        <div styleName="puzzle-headline-wrapper">
          <h1 styleName="headline">Welcome to DH's monthly Knowledge conquest</h1>
          <span styleName="description">
            This is our initiative to promote the benefits of reading a newspaper, and encourage students to stay
            updated with current affairs. The top 10 winners will be recognised and awarded, and may also get a shoutout
            in our newspaper. To be eligible for the same, please enter your contact details, and proceed!
          </span>
        </div>
      </div>
      <div styleName="widget-wrapper">
        {loadPuzzle && currentScreen === "" ? (
          userdetails?.metadata?.college && userdetails?.userData?.id && userdetails?.metadata?.displayName ? (
            <div styleName="widget">
              <span styleName="login-text">
                You’re logged in with Name: <strong>{userdetails?.metadata?.displayName}</strong> & College:{" "}
                <strong>{userdetails?.metadata?.college}</strong>
              </span>
              <div
                data-uid={userdetails?.userData?.id}
                className="pm-embed-div"
                data-set="dhcirculation-news-crossword"
                data-page="date-picker"
                data-height={isMobile ? "1020px" : "750px"}
                data-embedparams="embed=js&style=1"
              ></div>
            </div>
          ) : (
            <div styleName="widget">
              <span styleName="login-text">
                Login with name/college to win.{" "}
                <span styleName="update-here-text" onClick={handleUpdateClick}>
                  Update Here
                </span>{" "}
                or Play On!
              </span>
              <div
                className="pm-embed-div"
                data-set="dhcirculation-news-crossword"
                data-page="date-picker"
                data-height={isMobile ? "1020px" : "750px"}
                data-embedparams="embed=js&style=1"
              ></div>
            </div>
          )
        ) : (
          <h2 styleName="waiting-text">Wait for the widget to load...</h2>
        )}
        <div styleName="leaderboard">
          <iframe
            src="https://dulsrnuyc829c.cloudfront.net/crosswords/winners.html"
            title="Winners"
            width="100%"
            height="750px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </div>

      {!loadPuzzle && (
        <div styleName="overlay">
          <div styleName="contest-dialogue-box">
            {currentScreen === "" ? (
              <span>Loading...</span>
            ) : (
              <>
                <div
                  styleName="dialogue-close"
                  onClick={() => {
                    if (currentScreen === "info") {
                      clickHandler("info-yes");
                    } else {
                      setCurrentScreenWithRefUpdate("info");
                    }
                  }}
                >
                  &#10005;
                </div>
                {currentScreen === "start" && <StartScreen />}
                {currentScreen === "info" && <InfoScreen />}
                {currentScreen === "form" && <FormScreen openTermsHandler={termConditionModalOpenHandler} />}
              </>
            )}
          </div>
          <div styleName="term-condition-modal">
            <Modal onClose={termConditionModalCloseHandler} isOpen={termConditionModal}>
              <h2 styleName="title">Terms & Conditions</h2>
              <div styleName="term-condition-inner">
                <div styleName="subtitle">Terms &amp; Conditions for the Knowledge Conquest Quiz Competition:</div>
                <div styleName="dialogue-close" onClick={termConditionModalCloseHandler}>
                  &#10005;
                </div>
                {TERMS_CONDITIONS.map((term, index) => {
                  const { termTitle, subTerms } = term;
                  return (
                    <div key={index}>
                      <span styleName="term-title">
                        {index + 1}. {termTitle}
                      </span>
                      <ul styleName="subterms-wrapper">
                        {subTerms.map((subTerm, idx) => (
                          <li styleName="subterm" key={idx}>
                            {subTerm}
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
                <span styleName="contact">
                  For any queries or further information, please contact us at{" "}
                  <Link href="mailto:support@deccanherald.co.in">support@deccanherald.co.in</Link>
                </span>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}

export { StudentContestPage };

import React from "react";
import "./share-collection.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

const SOCIAL_MEDIA_SHARE = [
  { name: "whatsapp", type: "whatsappicon", bgColor: "#24d366" },
  { name: "facebook", type: "facebookicon", bgColor: "#32529f" },
  { name: "twitter", type: "twittericon", bgColor: "#ffffff" },
  { name: "linkedin", type: "linkedinicon", bgColor: "#0277b5" },
  { name: "mail", type: "mailicon", bgColor: "#000000" }
];

const socialMediaUrl = (type) => {
  const publishedURL = window.location.href;
  const encodedText = `Read more`;
  let url;

  switch (type) {
    case "facebook":
      url = `http://en-gb.facebook.com/sharer/sharer.php?utm_source=deccanherald&utm_medium=facebook&u=${encodeURIComponent(
        publishedURL + "?utm_source=facebook&utm_medium=referral&utm_campaign=socialshare"
      )}&t=${encodedText}`;
      break;
    case "twitter":
      url = `https://twitter.com/intent/tweet?utm_source=deccanherald&utm_medium=twitter&url=${encodeURIComponent(
        publishedURL + "?utm_source=twitter&utm_medium=referral&utm_campaign=socialshare"
      )}&text=${encodedText}&via=deccanherald`;
      break;
    case "whatsapp":
      url = `https://api.whatsapp.com/send?utm_source=deccanherald&utm_medium=whatsapp&text=${encodedText} ${encodeURIComponent(
        publishedURL + "?utm_source=whatsapp&utm_medium=referral&utm_campaign=socialshare"
      )}`;
      break;
    case "linkedin":
      url = `http://www.linkedin.com/shareArticle?utm_source=deccanherald&utm_medium=linkedin&mini=true&title=${encodedText}
      &url=${encodeURIComponent(publishedURL + "?utm_source=linkedin&utm_medium=referral&utm_campaign=socialshare")}`;
      break;
    case "mail":
      url = `mailto:?subject=${encodedText}&body=${encodedText}:%20${encodeURIComponent(
        publishedURL + "?utm_source=mail&utm_medium=referral&utm_campaign=socialshare"
      )}`;
      break;
    default:
      url = "";
      break;
  }
  return url;
};

const ShareCollection = () => {
  return (
    <div className="social-wrapper">
      {SOCIAL_MEDIA_SHARE.map((share, index) => (
        <a
          key={index}
          style={{ backgroundColor: `${share.bgColor}` }}
          target="_blank"
          rel="nofollow noreferrer"
          href={socialMediaUrl(share.name)}
        >
          <SvgIconHandler type={share.type} />
        </a>
      ))}
    </div>
  );
};

export default ShareCollection;

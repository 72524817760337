import React, { useRef, useState, useEffect } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { getCollection } from "../../helper/api";
import { setLoading } from "../../../components/store/actions";

import "./instagram-page.m.css";
import InstagramCard from "../../molecules/instagram-card";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import ShareCollection from "../../molecules/share-collection";
import Separator from "../../atoms/separator";
import { AdContainer } from "../../ads";

const InstagramSection = ({ data, collection }) => {
  const cardsPerPage = 8;
  const [stories, setStories] = useState(data.collection?.items?.map((i) => i.story).slice(0, 20) || []);
  const [page, setPage] = useState(1);
  const [toggleSharing, setToggleSharing] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();
  const totalCount = get(data, ["collection", "total-count"], 0);

  if (!stories.length) {
    return <h1 styleName="text-info">No Results Available</h1>;
  }

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const loadStories = async () => {
    // for Page 1 offset will be 20 else
    const offset = 20 + cardsPerPage * (page - 1);
    setPage(page + 1);
    const slug = data.collection?.slug;

    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getCollection("instagram", offset, cardsPerPage)
      .then((i) => setStories([...stories, ...i]))
      .finally(() => dispatch(setLoading(false)));
  };

  const sharingHandler = () => {
    setToggleSharing(!toggleSharing);
  };

  return (
    <div className="container" styleName="wrapper">
      <div>
        <div styleName="title">
          <SvgIconHandler type="instagramcollection" height={"32px"} width={"32px"} />
          <h1>{data?.section?.["display-name"] || data?.section?.name || data?.collection?.name}</h1>
          <div styleName="share-icon-wrapper">
            <button styleName="share" onClick={sharingHandler}>
              <SvgIconHandler type="shareblueicon" height={"28px"} width={"28px"} />
            </button>
            <div styleName="share-options">{toggleSharing && <ShareCollection />}</div>
          </div>
        </div>
        <p>Tap on any Image to read the story.</p>
        {!isMobile ? (
          <div className="hide-mobile">
            <div styleName="cards-wrapper">
              {stories?.slice(0, 12).map((story, index) => (
                <InstagramCard story={story} key={index} />
              ))}
            </div>
            {stories.length >= 12 && (
              <>
                <Separator />
                <div styleName="ad-wrapper" className="hide-mobile">
                  <AdContainer
                    AdClassName="standard-970x90"
                    id="div-gpt-ad-1680156254107-0"
                    desktop="DH_Desktop_HP_Billboard_Mid"
                  />
                </div>
                <Separator />
              </>
            )}
            <div styleName="cards-wrapper">
              {stories.slice(12, 20).map((story, index) => (
                <InstagramCard story={story} key={index} />
              ))}
            </div>
            {stories.length >= 20 && (
              <>
                <Separator />
                <div styleName="ad-wrapper" className="hide-mobile">
                  <AdContainer
                    AdClassName="standard-970x90"
                    id="div-gpt-ad-1675837026472-0"
                    desktop="DH_Desktop_HP_Billboard_Bottom"
                  />
                </div>
                <Separator />
              </>
            )}
            <div styleName="cards-wrapper">
              {stories.slice(20).map((story, index) => (
                <InstagramCard story={story} key={index} />
              ))}
            </div>
          </div>
        ) : (
          <div className="hide-desktop">
            <div styleName="cards-wrapper">
              {stories?.map((story, index) => (
                <>
                  <InstagramCard story={story} key={index} />
                  {index === 1 ? (
                    <>
                      <div styleName="ad-wrapper-mob">
                        <AdContainer
                          AdClassName="standard-300x250"
                          id="div-gpt-ad-1680156938221-0"
                          desktop="DH_MWeb_HP_Top"
                        />
                      </div>
                    </>
                  ) : null}
                  {index === 5 ? (
                    <>
                      <div styleName="ad-wrapper-mob">
                        <AdContainer
                          AdClassName="standard-300x250"
                          id="div-gpt-ad-1680156970809-0"
                          desktop="DH_MWeb_HP_Mid"
                        />
                      </div>
                    </>
                  ) : null}
                  {index === 9 ? (
                    <>
                      <div styleName="ad-wrapper-mob">
                        <AdContainer
                          AdClassName="standard-300x250"
                          id="div-gpt-ad-1680156998073-0"
                          desktop="DH_MWeb_HP_Bottom"
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        )}
        {totalCount > stories.length ? (
          <div styleName="btn-container">
            <button styleName="load-more-btn" onClick={loadStories}>
              Load More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

InstagramSection.propTypes = {
  pageType: string,
  collection: object,
  sectionData: object,
  data: shape({
    collection: object,
    section: string
  })
};

export { InstagramSection };

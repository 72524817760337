import React, { useState } from "react";
import get from "lodash/get";
import { string } from "prop-types";

import { useDispatch } from "react-redux";

import AuthorListingCard from "../../molecules/author-listing-card";
import Pagination from "../../molecules/pagination";

import { setLoading } from "../../../components/store/actions";
import { getAuthors } from "../../helper/api";
import TitleWithBorder from "../../molecules/title-with-border";
import { updateUrl } from "../../helper/utils";

import "./author-listing-page.m.css";

const AuthorListingPage = (props) => {
  const { data } = props;

  const dispatch = useDispatch();
  const defaultAuthorList = data.authors;
  const [pageNum, setPageNum] = useState(data?.pageNum);
  const [authors, setAuthors] = useState(defaultAuthorList);
  const totalCount = get(data, ["page", "total"], 0);
  const cardsPerPage = 20;

  const getMoreAuthors = async (page) => {
    const offset = (page - 1) * cardsPerPage;

    dispatch(setLoading(true));

    await getAuthors(offset, cardsPerPage)
      .then((i) => {
        setAuthors(i.authors);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <div className="container" styleName="wrapper">
      <TitleWithBorder name="Authors" />
      {authors.length > 0 ? (
        <>
          <div styleName="collection-wrapper">
            {authors.slice(0, cardsPerPage).map((item, index) => (
              <AuthorListingCard author={item} key={index} />
            ))}
          </div>
          <Pagination
            loadMoreStories={getMoreAuthors}
            totalItems={totalCount}
            perPage={cardsPerPage}
            initialPage={pageNum}
          />
        </>
      ) : (
        <h1 styleName="text-info">No Authors found!</h1>
      )}
    </div>
  );
};

AuthorListingPage.propTypes = {
  pageType: string
};

export { AuthorListingPage };

import React from "react";

const LinkedinIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M8.679 17H6.187V8.991H8.68V17zM7.446 7.893a1.39 1.39 0 0 1-1.017-.429A1.39 1.39 0 0 1 6 6.446c0-.392.143-.732.429-1.017A1.39 1.39 0 0 1 7.446 5c.393 0 .733.143 1.018.429.286.285.429.625.429 1.017 0 .393-.143.733-.429 1.018a1.39 1.39 0 0 1-1.018.429zM18 17h-2.491v-3.91c0-.644-.054-1.108-.16-1.394-.197-.482-.581-.723-1.153-.723-.571 0-.973.214-1.205.643-.179.322-.268.795-.268 1.42V17H10.26V8.991h2.384v1.098h.027c.178-.357.464-.651.857-.884.428-.285.928-.428 1.5-.428 1.16 0 1.973.366 2.437 1.098.357.59.536 1.5.536 2.732V17z"
        id="qg855s3nba"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
        fill="#0077B5"
        fillRule="nonzero"
      />
      <use fill="#FFF" xlinkHref="#qg855s3nba" />
    </g>
  </svg>
);

export default LinkedinIcon;

import React from "react";
import { object, array, bool, number, string } from "prop-types";
import { Link } from "@quintype/components";
import get from "lodash/get";

import CardImage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import SocialMediaShare from "../social-media-share";
import StoryDate from "../../atoms/story-date";

import "./listing-story-card.m.css";

const ListingStoryCard = ({
  story,
  aspectRatio,
  imageType,
  headerType,
  showAuthorName,
  id,
  showRedDot,
  headerLevel,
  sectionName,
  toggleSharing,
  ToggleHandler
}) => {
  if (!story) return null;

  const isClosed = get(story, ["metadata", "is-closed"], false);
  const headline = get(story, ["headline"], "");

  return (
    <>
      <div className="listing-story-card hide-mobile" styleName="listing-card">
        <div styleName="card-wrapper">
          <div styleName="text-content">
            <Link href={`/${story.slug}`}>
              <div styleName="heading-content">
                <Headline
                  text={headline}
                  headerType={headerType}
                  headerLevel={headerLevel}
                  story={story}
                  width={20}
                  height={20}
                  id={id}
                  showRedDot={showRedDot}
                  lineClamp={2}
                />
              </div>
            </Link>
            <Link href={`/${story.slug}`}>
              <span styleName="summary" className="hide-mobile summary line-clamp-2">
                {story?.subheadline
                  ? story?.subheadline
                  : story.seo && story.seo["meta-description"]
                  ? story.seo["meta-description"]
                  : null}
              </span>
            </Link>
            <Link href={`/${story.slug}`}>
              <div styleName="timestamp">
                <div styleName="date">
                  <StoryDate story={story} />
                </div>
              </div>
            </Link>
          </div>
          <div styleName="video-card">
            <Link href={`/${story.slug}`}>
              <CardImage
                story={story}
                aspectRatio={aspectRatio}
                imageType={imageType}
                renderedWidth={720}
                widths={[700]}
                showAuthorName={showAuthorName}
                showStoryTypeIcon
                showAlternateImage
                showVideoIcon={true}
              />
            </Link>
            <div styleName="share-icon">
              <div styleName="share-icon-wrapper">
                <div styleName="share-options">
                  {toggleSharing === story.headline && <SocialMediaShare story={story} />}
                </div>
                <button styleName="share" onClick={() => ToggleHandler(story.headline)}>
                  <SvgIconHandler type="share" height="40" width="40" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="listing-story-card hide-desktop" styleName="listing-card">
        <div styleName="wrapper">
          <Link href={`/${story.slug}`} styleName="card-image">
            <CardImage
              story={story}
              aspectRatio={[16, 9]}
              imageType="image16x9"
              renderedWidth={720}
              showVideoIcon={true}
              widths={[700]}
            />
          </Link>
          <div styleName="headline-mobile">
            <Link href={`/${story.slug}`}>
              <Headline
                text={headline}
                headerType={headerType}
                headerLevel={headerLevel}
                story={story}
                width={20}
                height={20}
                id={id}
                showRedDot={showRedDot}
                lineClamp={3}
              />
            </Link>
          </div>
          <div></div>
          <div styleName="bottom-wrapper">
            <Link href={`/${story.slug}`}>
              <div styleName="timestamp">
                <div styleName="date">
                  <StoryDate story={story} />
                </div>
              </div>
            </Link>
            <div styleName="share-icon-wrapper">
              <button styleName="share" onClick={() => ToggleHandler(story.headline)}>
                <SvgIconHandler type="share2" height="24" width="24" />
              </button>
              <div styleName="share-options">
                {toggleSharing === story.headline && <SocialMediaShare story={story} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ListingStoryCard.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9",
  headerType: 8,
  headerLevel: 1,
  showAuthorName: false,
  showSummary: true,
  isExternalStory: false,
  showRedDot: false
};

ListingStoryCard.propTypes = {
  story: object.isRequired,
  aspectRatio: array,
  imageType: string,
  headerType: number,
  headerLevel: number,
  showAuthorName: bool,
  showSummary: bool,
  id: string,
  isExternalStory: bool,
  pageType: string,
  showRedDot: bool
};

export default ListingStoryCard;

import React, { useEffect, useState, useRef } from "react";
import "./election-brick-wall-with-search.m.css";

import Separator from "../../atoms/separator";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

const ElectionBrickWallWithSearch = ({
  initialStateName = "Jammu & Kashmir",
  electionData = {},
  stateSlugData = []
}) => {
  const [activeState, setActiveState] = useState(initialStateName);
  const [constituencyData, setConstituencyData] = useState(electionData?.[activeState]);
  const [loadMoreOpen, setLoadMoreOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showLoadMore, setLoadMore] = useState(false);

  const stateData = stateSlugData.filter((state) => state?.State === activeState);
  const [activeStateData, setActiveStateData] = useState(stateData[0]);
  const constituencyRef = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const filteredrecords =
      value && Object.keys(electionData).filter((item) => item.toLowerCase().includes(value.toLowerCase()));
    setFilteredRecords(filteredrecords);
  };

  const onStateClick = (state) => {
    setActiveState(state);
    setConstituencyData(electionData?.[state]);
    setLoadMoreOpen(false);
    setInputValue("");
  };

  useEffect(() => {
    const activeStateInformation = stateSlugData.filter((state) => state?.State === activeState);
    setActiveStateData(activeStateInformation[0]);

    if (constituencyRef.current) {
      if (constituencyRef.current.clientHeight > 108) {
        setLoadMore(true);
      } else {
        setLoadMore(false);
      }
    }
  }, [activeState]);

  return (
    <div styleName="wrapper">
      <div styleName="heading-wrapper">
        <div styleName="title-pipe"></div>
        <div styleName="heading-and-search-wrapper">
          <span styleName="brick-wall-heading">LOK SABHA ELECTIONS 2024 | CONSTITUENCY-WISE RESULTS</span>
          {"    "}
          <div styleName="search-wrapper">
            <div styleName="search-container" className="search-container">
              <input
                placeholder="Search Your State"
                type="text"
                styleName="search-input-box"
                className="search-input-box"
                value={inputValue}
                onChange={handleChange}
              />
              <button styleName="search-btn">
                <SvgIconHandler type="blacksearch" height="18px" width="18px" />
              </button>
            </div>
            <div styleName="dropdown">
              {inputValue.length > 0 &&
                (filteredRecords.length > 0 ? (
                  filteredRecords.slice(0, 5).map((item, index) => (
                    <div
                      styleName="dropdown-item"
                      key={item?.id}
                      onClick={() => {
                        onStateClick(item);
                      }}
                    >
                      {item}
                    </div>
                  ))
                ) : (
                  <div styleName="dropdown-item-not-found">No State found</div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        styleName={`constituency-wrapper ${!loadMoreOpen && showLoadMore ? "keep-clamp" : ""}`}
        ref={constituencyRef}
      >
        <a styleName="constituency-name state-name" href={activeStateData?.redirectionUrl}>
          {activeStateData?.State?.toUpperCase()}
        </a>
        {constituencyData.map((constituency, index) => (
          <a styleName="constituency-name" href={constituency?.publishedUrl} key={constituency?.id}>
            {constituency?.constituencyName}
          </a>
        ))}
        {!loadMoreOpen && showLoadMore && (
          <span
            onClick={() => {
              setLoadMoreOpen(true);
            }}
            styleName="load-more-button-wrapper"
          >
            <span styleName="load-more-button">Load More</span>
          </span>
        )}
      </div>
      <div styleName="state-name-wrapper">
        <span styleName="state-menu-title">Lok Sabha Polls 2024</span> |{" "}
        {stateSlugData.length > 0 &&
          stateSlugData.map((stateDetails) => {
            return (
              <>
                <a styleName="state-menu-item" href={`/${stateDetails?.redirectionUrl}`}>
                  {stateDetails?.State}
                </a>
                <span styleName="pipe-symbol">|</span>
              </>
            );
          })}
      </div>
      <Separator />
    </div>
  );
};

export default ElectionBrickWallWithSearch;

import React, { useState } from "react";
import ShareCollection from "../share-collection";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

import "./tag-card.m.css";

const TagCard = ({ data, ref }) => {
  const [toggleSharing, setToggleSharing] = useState(false);
  const sharingHandler = () => {
    setToggleSharing(!toggleSharing);
  };
  const SectionName = data?.section?.name;
  const CollectionName = data?.collection.metadata?.["display-name"] || data?.collection?.name;
  return (
    <div styleName="wrapper">
      <div styleName="content">
        <div ref={ref} styleName="title">
          <h1>{SectionName || CollectionName}</h1>
        </div>
        <div styleName="share-icon-wrapper">
          <button styleName="share" onClick={sharingHandler}>
            <SvgIconHandler type="share2" height="24" width="24" />
          </button>
          <div styleName="share-options">{toggleSharing && <ShareCollection />}</div>
        </div>
      </div>
    </div>
  );
};

export default TagCard;

import React from "react";
import get from "lodash/get";
import { object, array, number, string } from "prop-types";
import { Link } from "@quintype/components";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/cardimage";

import "./news-shots-card.m.css";

const NewsShotsCard = ({ story, aspectRatio, imageType, headerType, headerLevel }) => {
  const sectionName = get(story, ["sections", "0", "name"], "");
  return (
    <div styleName="wraper">
      <Link href={`/${story.slug}`} styleName="link-wrapper">
        <CardImage story={story} aspectRatio={aspectRatio} imageType={imageType} renderedWidth={720} widths={[700]} />
        <div styleName="content">
          <Link href={`/${story.slug}`} styleName="section-name">
            {sectionName}
          </Link>
          <Headline
            text={story.headline}
            headerType={headerType}
            headerLevel={headerLevel}
            story={story}
            lineClamp={3}
          />
          <div className="hide-mobile">
            <span styleName="summary" className=" summary line-clamp-7">
              {story?.subheadline
                ? story?.subheadline
                : story.seo && story.seo["meta-description"]
                ? story.seo["meta-description"]
                : null}
            </span>
          </div>
          <div className="hide-desktop">
            <span styleName="summary" className=" summary line-clamp-5">
              {story?.subheadline
                ? story?.subheadline
                : story.seo && story.seo["meta-description"]
                ? story.seo["meta-description"]
                : null}
            </span>
          </div>
        </div>
        <button styleName="button">Full Story</button>
      </Link>
    </div>
  );
};

NewsShotsCard.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9",
  headerType: 11,
  headerLevel: 2
};

NewsShotsCard.propTypes = {
  story: object.isRequired,
  aspectRatio: array,
  imageType: string,
  headerType: number,
  headerLevel: number
};

export default NewsShotsCard;

import React, { useEffect } from "react";
import "./search-bar.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { SearchBox } from "@quintype/components";

function SearchBar({ defaultValue }) {
  const SearchIconTemplate = ({ children }) => (
    <div styleName="search-wrapper">
      {children}
      <button type="submit" styleName="search search-btn">
        <SvgIconHandler type="blacksearch" height="24px" width="24px" />
      </button>
    </div>
  );

  useEffect(() => {
    window.onload = function () {
      document.querySelector("input").focus();
    };
  }, []);

  return (
    <SearchBox
      placeholder="Search here…"
      inputId="searchForm"
      inputClassName="search__form-input"
      template={SearchIconTemplate}
      initValue={defaultValue}
    />
  );
}

export default SearchBar;

import React, { useState, useRef, useEffect } from "react";
import { string, shape, object, bool } from "prop-types";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import Separator from "../../atoms/separator";
import SectionBreadcrum from "../../atoms/section-breadcrum";

import TagCard from "../../molecules/tag-card";

import { getCollection } from "../../helper/api";
import { updateUrl } from "../../helper/utils";
import { setLoading } from "../../../components/store/actions";
import ListingPageTemplate from "../../collection-templates/listing-page-template";
import { AdContainer } from "../../ads";
import StorySideBar from "../../story-templates/story-sidebar";

import "./sub-section-page.m.css";

const SubSectionPage = ({ data, isSection, title }) => {
  const subSectionStories = data.collection?.items?.map((i) => i.story);
  const [stories, setStories] = useState(subSectionStories || []);
  const [pageNum, setPageNum] = useState(data?.pageNum);
  const dispatch = useDispatch();
  const ref = useRef();
  const totalCount = get(data, ["collection", "total-count"], 0);
  const cardsPerPage = 10;
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  if (!stories.length) {
    return <h1 styleName="text-info">No results available</h1>;
  }

  const loadStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    const slug = data.collection?.slug;

    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getCollection(slug, offset, cardsPerPage)
      .then((i) => {
        setStories(i);
        setPageNum(page);
        updateUrl(page, pageNum);
      })
      .finally(() => dispatch(setLoading(false)));
  };
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const currentPath = get(qtState, ["currentPath"], "");

  const modifiedPath = currentPath.split("?")[0];

  return (
    <>
      <div className="container" styleName="main-container">
        {!isMobile ? (
          <div className="hide-mobile">
            <Separator />
            <div className="hide-mobile ad ad-bgc">
              <AdContainer
                AdClassName="standard-970x250"
                id="div-gpt-ad-1680156194475-0"
                desktop="DH_Desktop_HP_Billboard_Top"
              />
            </div>
          </div>
        ) : null}
        {!isSection && <Separator />}
        <SectionBreadcrum initialPath={modifiedPath} type="section-page" />
        <div styleName="wrapper" ref={ref}>
          <div>
            {isSection ? (
              <div styleName="heading">
                <h1>{title}</h1>
                <Separator />
              </div>
            ) : (
              <>
                <TagCard data={data} />
                <Separator />
              </>
            )}
            <ListingPageTemplate
              loadStories={loadStories}
              storiesList={stories}
              perPage={cardsPerPage}
              totalCount={totalCount}
              sectionName={title}
              initialPage={pageNum}
            />
          </div>
          <div className="side-story-section hide-mobile">
            <StorySideBar />
          </div>
        </div>
      </div>
    </>
  );
};

SubSectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: object
  }),
  isSection: bool,
  title: string
};

export { SubSectionPage };

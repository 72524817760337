import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import TitleWithBorder from "../../molecules/title-with-border";
import "./newsletter-unsubscribe.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { unSubscribeNewsLetter } from "../../helper/api";
import { NEWS_LETTER_DATA } from "../newsletter-page/newletter-data";

const NewsLetterUnsubscribePage = () => {
  const [newsletterName, setNewsletterName] = useState("");
  const [reason, setReason] = useState("Please select a reason");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showContactMessage, setContactMessage] = useState(false);
  const [email, setEmail] = useState(null);
  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (window !== undefined && typeof window !== undefined) {
      const urlparams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlparams.entries());

      if (params.hasOwnProperty("email")) {
        setEmail(params.email);
      }
      if (params.hasOwnProperty("tag")) {
        setTag(params.tag);
        setNewsletterName(NEWS_LETTER_DATA.find((tag) => tag.tag_name === params.tag)?.title || "");
      }
    }
  }, []);

  const dropdownSelect = (e) => {
    setReason(e.target.textContent);
    setOpen(!open);
    setErrorMessage("");
    setContactMessage(false);
  };

  const unsubscribeHandler = (e) => {
    if (reason === "Please select a reason") {
      setErrorMessage("Please select a reason from dropdown!");
    } else if (email !== null) {
      // Unsubscribing a user from list
      const body = {
        email: email,
        reason: reason,
        tag: tag
      };

      unSubscribeNewsLetter(body)
        .then((res) => {
          setSuccessMessage(res || "Unsubscribed!");
          setErrorMessage("");
          setContactMessage(false);
        })
        .catch((err) => {
          setContactMessage(true);
          setSuccessMessage("");
          console.log("err :>> ", err);
        });
    }
  };

  return (
    <div className="container" styleName="unsubscribe-wrapper">
      <TitleWithBorder name="Newsletters" />
      <div styleName="text-container">
        <span styleName="body-text-1">
          Didn't mean to unsubscribe? Click to{" "}
          <a styleName="cancel" href="/newsletters">
            CANCEL
          </a>
        </span>
        <span styleName="body-text-2">Unsubscribe from the {newsletterName} Newsletter</span>
        <span styleName="body-text-3">
          To help us improve our services, we would be grateful if you could tell us why
        </span>
        <div styleName="action-wrapper">
          <div styleName="dropdown">
            <div
              styleName="dropdown-text-wrapper"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <span>{reason}</span>
              <SvgIconHandler type="downarrow" height="22px" width="24px" />
            </div>
            {open && (
              <div styleName="dropdown-options ">
                <span styleName="reason-text" onClick={dropdownSelect}>
                  Not relevant to me anymore
                </span>
                <span styleName="reason-text" onClick={dropdownSelect}>
                  I'll be back after some time
                </span>
                <span styleName="reason-text" onClick={dropdownSelect}>
                  Need more variety of content
                </span>
              </div>
            )}
          </div>

          <button styleName="unsubscribe-button" onClick={(e) => unsubscribeHandler(e)}>
            UNSUBSCRIBE
          </button>
        </div>
        {showContactMessage && (
          <span styleName="message">
            {" "}
            Error while unsubscribing? Please contact
            <a styleName="mail-to-text" href="mailto:newsletters@email.deccanherald.com">
              newsletters@email.deccanherald.com
            </a>
          </span>
        )}
        {errorMessage.length > 0 && <span styleName="message error">{errorMessage}</span>}
        {successMessage.length > 0 && <span styleName="message success">{successMessage}</span>}
        <a styleName="checkout-button" href="/newsletters">
          Check out other newsletters
        </a>
      </div>
    </div>
  );
};

export { NewsLetterUnsubscribePage };
